<template>
    <div class="answerBody">
        <el-radio-group v-if="bool(item)" v-model="answer">
            <el-radio v-for="option in boolSelectValues" :key="option.value" :label="option.value">{{option.name}}
            </el-radio>
        </el-radio-group>
        <el-radio-group v-else-if="single(item)" v-model="answer">
            <el-radio v-for="option in item.options" :key="option.id" :label="option.name">
                <span style="white-space: normal;line-height: 24px;">{{option.name}}</span>
                <el-tooltip class="item" effect="light" placement="top" v-if="option.desc">
                    <i class="icon iconfont icon-wenhao" style="margin-left: 8px;vertical-align: middle;" @click.stop
                       @mousedown.prevent.stop></i>
                    <template #content>
                        <span v-html="option.desc" style="white-space: pre-wrap"></span>
                    </template>
                </el-tooltip>
            </el-radio>
        </el-radio-group>
        <el-checkbox-group v-else-if="multiple(item)" v-model="answer" size="mini">
            <el-checkbox v-for="option in item.options" :key="option.id" :label="option.name">
                <span style="white-space: normal;line-height: 24px;">{{option.name}}</span>
                <el-tooltip class="item" effect="light" placement="top" v-if="option.desc">
                    <i class="icon iconfont icon-wenhao" style="margin-left: 8px;vertical-align: middle;" @click.stop
                       @mousedown.prevent.stop></i>
                    <template #content>
                        <span v-html="option.desc" style="white-space: pre-wrap"></span>
                    </template>
                </el-tooltip>
            </el-checkbox>
        </el-checkbox-group>
        <el-input v-else
                  type="textarea"
                  :rows="1"
                  :placeholder="placeholder?placeholder:'请输入答案'"
                  v-model="answer">
        </el-input>
        <span v-if="noOptions(item)" class="dangerText">您尚未添加可选项</span>
    </div>
</template>

<script>
    import {
        BOOL_OPTIONS,
        getOrProduceQuestionDefaultAnswer,
        getQuestionDefaultAnswer,
        noOptions
    } from "../constant/question";
    import {bool, multiple, single, singleOrMultiple} from "./common/question";

    export default {
        name: "DocQuestionAnswerBody",
        props: [ "question","answerContent","updateAnswerFunc","placeholder"],
        data: function () {
            return {
                boolSelectValues: BOOL_OPTIONS,
                bool: bool,
                item: this.question,
                single: single,
                multiple: multiple,
                noOptions: noOptions,
                singleOrMultiple: singleOrMultiple,
                answer: this.getInitAnswer(this.question,this.answerContent),
            };
        },
        watch: {
            'answer': function () {
                if (this.updateAnswerFunc) {
                    this.updateAnswerFunc(this.answer)
                }
            }
        },
        mounted() {

        },
        methods: {
            getInitAnswer:function (question,answerContent) {
                if (answerContent === null) {
                    return getOrProduceQuestionDefaultAnswer(question, true);
                }
                if (typeof answerContent === "number") {
                    return String(answerContent);
                }
                return answerContent;
            },
            //重新初始化答案
            reInitAnswer:function (question,answerContent) {
                this.item = question;
                this.answer = this.getInitAnswer(question,answerContent);
            }
        }
    }
</script>

<style  lang="scss">
    .answerBody {
        text-align: left;
        .el-radio, .el-checkbox {
            display: block;
            margin: 5px 0 5px 5px;
        }
    }
</style>

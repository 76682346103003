<template>
    <div style="width: 100%;" ref="container">
        <el-popover trigger="click" :width="popoverWidth" v-model:visible="visible" popper-class="selectorPopover"
                    @show="popoverShow">
            <template #reference>
                <el-input v-model="text" :placeholder="placeHolderReal" :size="sizeReal" readonly  @pointerdown.stop>
                    <template #suffix>
                        <i class="el-input__icon el-icon-arrow-down" @click="visible=!visible"></i>
                    </template>
                </el-input>
            </template>
            <el-input v-model="newItemName" :size="sizeReal" :placeholder="createItemPlaceholderReal" class="newItemInput"  @keydown.enter="createItem(newItemName)">
                <template #suffix>
                    <i class="el-input__icon el-icon-plus" style="cursor: pointer" @click="createItem(newItemName)"></i>
                </template>
            </el-input>

            <div style="margin-top: 5px;line-height: 30px;min-height:154px;max-height: 230px;overflow-y: auto;" v-if="visible">
                <!--                    <div v-if="items.length===0" style="text-align: center;min-height: 154px;height: 100%;display: flex;justify-content: center;align-items: center;" @click="createItem()">-->
                <!--                        <i class="el-input__icon el-icon-plus"  style="cursor: pointer"></i>-->
                <!--                    </div>-->
                <div v-for="(element,index) in items" @click="startEditItem(element)" :title="element.desc">
                    <div v-if="element.id===editItem.id&&editMode===EditMode.NAME">
                        <el-input v-model="editItem.name" @click.stop @mouseover.prevent.stop class="editOptionElInput">
                            <template #suffix>
                                <i class="el-input__icon el-icon-check" style="cursor: pointer"
                                   @click.stop="saveEditItem()"></i>
                                <i class="el-input__icon el-input__right el-icon-close" style="cursor: pointer"
                                   @click.stop="resetToSelectMode()"></i>
                            </template>
                        </el-input>
                    </div>
                    <div class="menuItem" style="padding-right: 120px;" v-else>
                        <span >{{element.name}}</span>
                        <div  class="optionGroup">
                            <i class="el-icon  el-icon-top" title="上移"
                               @click.stop="moveUpItem(index)"></i>
                            <i class="el-icon el-icon-bottom" title="下移"
                               @click.stop="moveDownItem(index)"></i>
                            <i class="el-icon  el-icon-edit"
                               @click.stop="startEditItem(element,EditMode.NAME)" title="修改选项名"></i>
                            <i class=" iconfont icon-wenhao"
                               @click.stop="startEditItem(element,EditMode.DESC)" title="修改选项描述"></i>
                            <i class="el-icon  el-icon-delete"
                               @click.stop="deleteItem(element)" title="删除选项"></i>
                            <!--                            <i class="el-input__icon el-icon-plus"  style="cursor: pointer" @click.stop="createItem()"></i>-->
                        </div>
                    </div>


                </div>

                <div v-if="detailVisible"
                     style="position: absolute;width: 100%;min-height: 100%;right:-100%;top:0px;background-color: #FFF;border: 1px solid #E4E7ED;display: flex;justify-content: space-between;flex-direction: column">
                    <div style="padding:8px;">
                        <!--                            <el-form-item>-->
                        <!--                                <el-input v-model="editItem.name" :size="sizeReal" placeholder="选项名" @click.stop>-->
                        <!--                                </el-input>-->
                        <!--                            </el-form-item>-->

                        <el-form-item style="margin-top: 10px;">
                            <el-input v-model="editItem.desc" :size="sizeReal" type="textarea" ref="descTextArea"
                                      rows="5"
                                      placeholder="在这里输入对该选项的描述"  @pointerdown.stop>

                            </el-input>
                        </el-form-item>
                    </div>
                    <div style="display: flex;justify-content: center;">
                        <el-button type="text" @click="saveEditItem()">保存</el-button>
                        <el-button type="text" @click="hideDetailBox()">取消</el-button>
                    </div>
                </div>

            </div>


        </el-popover>
    </div>
</template>

<script>
    const EditMode = {
        NAME: 'name',
        DESC: 'desc'
    }
    import draggable from 'vuedraggable'

    export default {
        name: "OptionsGenerator",
        props: ['itemId', 'items', 'placeHolder', 'createItemPlaceholder', 'size', 'saveItemFunc', 'deleteItemFunc', 'checkSelectFunc'],
        data: function () {
            return {
                text: null,
                visible: false,
                popoverWidth: 230,
                newItemName: '',
                editItemOrigin: null,
                editItem: this.produceEmptyEditOption(),
                editMode: EditMode.NAME,
                EditMode: EditMode,
                detailVisible: false,
                sizeReal: this.size ? this.size : 'medium',
                placeHolderReal: this.placeholder ? this.placeholder : '请添加选项',
                createItemPlaceholderReal: this.createItemPlaceholder ? this.createItemPlaceholder : '在这里新建选项',
            };
        },
        computed: {
            itemMap: function () {
                const result = new Map();
                if (this.items) {
                    this.items.forEach(item => result.set(item.id, item.name));
                }
                return result;
            },
        },
        watch: {
            'items': {
                handler: function (newval, oldval) {
                    this.updateText()
                },
                deep: true,
            }
        },
        mounted() {
            // 初始化时根据itemId获取设置输入框的名字。
            this.updateText()
        },
        methods: {
            produceEmptyEditOption: function () {
                return {id: null, name: '', desc: ''}
            },
            produceOption: function (name) {
                return {id: name, name: name, desc: ''};
            },
            popoverShow: function () {
                if (this.$refs.container) {
                    this.popoverWidth = this.$refs.container.clientWidth;
                }
            },
            updateText: function () {
                this.text = this.items.map(item => item.name).join(',');
            },
            createItem: function (name) {
                // this.setEditItem(this.produceEmptyEditOption());
                // this.showDetailBox();
                if ('' === name) {
                    this.$message.error('选项名不能为空');
                    return
                }
                if (this.items.find(item => item.name === name)) {
                    this.$message.error('该选项已存在');
                    return
                }
                let option = this.produceOption(name);
                this.items.push(option);
                this.newItemName = '';
            },
            /**
             * 设置当前编辑的选项是
             * @param editItem
             * @param editItemOrigin
             */
            setEditItem: function (editItem, editItemOrigin) {
                this.editItem = editItem;
                this.editItemOrigin = editItemOrigin;
            },
            saveEditItem: function () {
                if (!this.editItem.name) {
                    this.$message.error('请输入选项名');
                    return
                }
                this.editItem.id = this.editItem.name;
                // if (!this.editItem.id) {
                //     this.editItem.id = this.editItem.name;
                // }
                let otherSameNameOption = this.items.find(item => item !== this.editItemOrigin && item.name === this.editItem.name);
                if (otherSameNameOption) {
                    this.$message.error('已存在该选项名');
                    return
                }
                if (this.editItemOrigin) {
                    this.$emit('option-name-updated',{oldName:this.editItemOrigin.name, newName: this.editItem.name})
                    Object.assign(this.editItemOrigin, this.editItem);
                } else {
                    this.items.push(Object.assign({}, this.editItem));
                }
                this.hideDetailBox();
                this.resetToSelectMode();

            },
            startEditItem: function (item, editMode) {
                // this.setEditItem(Object.assign({}, item), item);
                // this.showDetailBox();

                this.editMode = editMode;
                this.setEditItem(Object.assign({}, item), item);
                if (editMode === EditMode.DESC) {
                    this.showDetailBox();
                } else {
                    this.hideDetailBox();
                }

            },
            showDetailBox: function () {
                this.detailVisible = true;
                console.log(this.$refs);
                this.$nextTick(()=>{
                    if (this.$refs.descTextArea) {
                        this.$refs.descTextArea.focus();
                    }
                });

            },
            hideDetailBox: function () {
                this.detailVisible = false;
            },
            resetToSelectMode: function () {
                let item = this.produceEmptyEditOption();
                item.id = -1;
                this.editItem = item;
            },
            deleteItem: function (item) {
                let index = this.items.indexOf(item);
                if (index >= 0) {
                    this.items.splice(index, 1)
                }
            },
            itemOrderChange: function (event) {
                console.log('item order changed************')
                console.log(event);
                console.log('item order changed************')
            },
            moveUpItem: function (index) {
                let item = this.items[index];
                if (index > 0) {
                    this.items[index] = this.items[index - 1];
                    this.items[index - 1] = item;
                }
            },
            moveDownItem: function (index) {
                let item = this.items[index];
                if (index < this.items.length - 1) {
                    this.items[index] = this.items[index + 1];
                    this.items[index + 1] = item;
                }
            }
        },
        components: {draggable}
    }
</script>

<style scoped>
    .menuItem {
        padding: 0 5px 0 10px;
        /*display: flex;*/
        /*justify-content: space-between;*/
        position: relative;
        padding-right: 120px;
        cursor: pointer;
    }
    .menuItem .optionGroup {
        width: 120px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
    }
    .menuItem .optionGroup i{
        width: 25px;
        line-height: 100%;
        text-align: center;
        vertical-align: middle;
    }

    .menuItem:hover {
        background-color: #f5f7fa;
    }
</style>
<style scoped>
    .selectorPopover {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .selectorPopover .el-textarea__inner, .selectorPopover .el-input__inner {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #DCDFE6;
        padding: 0 10px;
    }
    .newItemInput .el-input__inner{
        padding-right: 30px;
    }
    .editOptionElInput .el-input__inner{
        padding-right: 60px;
    }
</style>

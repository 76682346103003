<template>
    <novice-guide ref="guide" @guide-started="guideStartedFunc" @guide-error="guideErrorFunc"></novice-guide>
    <div class="leftMenu" :style="{width: `${leftWidth}px`}">

        <!--问题的编辑模式-->
        <div class="questionPanel" v-if="activeModel === ACTIVE_MODEL.EDIT_MODEL">
            <div style="height: 10px; background-color: transparent;"></div>
            <!-- <div style="text-align: left;margin-bottom: 10px;">
                <el-button size="mini" icon="el-icon-plus" @click="addDefaultQuestion()">添加</el-button>
            </div> -->
            <div id="editModeScroller" class="leftScroller" style="overflow-y: auto;overflow-x: hidden" :style="{height: scrollerHeight}">
                <el-collapse v-model="activeGroups" class="groupQuestionCollapse">

<!--                    <el-collapse-item  title="问答信息" :name="-2" >-->
<!--                        <template #title>-->
<!--                            <img src="../assets/images/groupMenu.png" class="groupMenuIcon"/>问答信息-->
<!--                        </template>-->
<!--                        <template-answer-sheet-basic-setting :file-id="fileId" @setting-changed="settingChanged" style="margin-top: 5px;"></template-answer-sheet-basic-setting>-->
<!--                    </el-collapse-item>-->

<!--                    <el-collapse-item v-for="(questions,index) in groupQuestions" :key="groupWithUnassigned[index].id" :title="groupWithUnassigned[index].name" :name="groupWithUnassigned[index].id" v-show="index!==groups.length||groupWithUnassigned[index].length>0">-->
<!--                        <draggable :list="questions"  item-key="id" :move="questionOrderChange" @end.prevent.stop="draggedEnd" group="question">-->
<!--                    todo 这里需要处理-->
                    <el-collapse-item v-for="(group,index) in groups" :key="group.id" :title="group.name" :name="group.id" class="doc-edit-mode-collapse" @dragover="questionDragover" :data-group-id="group.id">
                        <template #title>
                            <img src="../assets/images/groupMenu.png" class="groupMenuIcon"/>{{group.name}}
                        </template>
                        <draggable :list="groupQuestions[index]"  item-key="id" :move="questionOrderChange" @end.prevent.stop="draggedEnd" group="question">
                            <template #item="{element,i}">
                                <doc-question-edit-mode
                                        st="questionList"
                                        :active-id="activeQuestionId"
                                        v-if="!element.parentId"
                                        :logicDisabled="index===0&&i===0"
                                        :element="element" :controlItems="getControlItems(element)"
                                        @delete-question-dependency="deleteQuestionDependency"
                                        :groups="groups" :checkQuestionGroupChangeFunc="checkQuestionGroupChangeFunc" :groupOrderExchange="groupOrderExchange"
                                        @control-question-changed="controlAnsChanged"
                                        :deleteQuestionFunc="deleteQuestionFunc" :saveQuestionFunc="saveQuestion"
                                        :pushQuestion="pushQuestion" :deleteSubQuestions="deleteSubQuestions"
                                        :produceQuestionExtraUIInfo="produceQuestionExtraUIInfo"
                                        :highlightNextRect="highlightNextRect" :highlightPreviousNext="highlightPreviousNext"
                                        :answerNameExistFunc="answerNameExistFunc" :checkQuestionTypeChangeRelatedFunc="checkQuestionTypeChangeRelatedFunc"
                                        @question-option-name-updated="questionOptionNameUpdated"
                                        @mousedown="activeQuestionId=element.id" @drag="onDrag"
                                        @question-answer-info-changed="questionAnswerInfoChanged" @high-setting-expand-changed="highSettingExpandChanged"
                                ></doc-question-edit-mode>
                            </template>
                        </draggable>

                    </el-collapse-item>

<!--                    <el-collapse-item v-if="noGroupQuestions.length>0" title="未分组" :name="-1">-->
<!--                        <draggable :list="noGroupQuestions" item-key="id" :move="questionOrderChange"  @ended="draggedEnd" group="question">-->
<!--                            <template #item="{element,i}">-->
<!--                                <doc-question-edit-mode-->
<!--                                        st="questionList"-->
<!--                                        :logicDisabled="index===0&&i===0"-->
<!--                                        :element="element" :controlItems="getControlItems(element)"-->
<!--                                        :groups="groups" :checkQuestionGroupChangeFunc="checkQuestionGroupChangeFunc"-->
<!--                                        @control-question-changed="controlAnsChanged"-->
<!--                                        :deleteQuestionFunc="deleteQuestionFunc" :saveQuestionFunc="saveQuestion"-->
<!--                                        :highlightNextRect="highlightNextRect" :highlightPreviousNext="highlightPreviousNext"-->
<!--                                        :answerNameExistFunc="answerNameExistFunc" :checkQuestionTypeChangeRelatedFunc="checkQuestionTypeChangeRelatedFunc"-->
<!--                                        @question-answer-info-changed="questionAnswerInfoChanged"-->
<!--                                ></doc-question-edit-mode>-->
<!--                            </template>-->
<!--                        </draggable>-->

<!--                    </el-collapse-item>-->
                </el-collapse>


                <div id="answer-tip" v-show="initDataFinished&&items&&items.length===0">
                    <img src="../assets/images/thinking.png" alt="" style="height: 350px;width:467px;margin-left: 50%;transform: translateX(-50%);">
                    <div style="font-weight: bold;text-align: center;font-size: 16px;">想知道如何将Word文档转换为智能模板吗？</div>
                    <div style="margin-top: 10px;font-size: 20px;font-weight: bold;cursor:pointer;line-height: 30px;" @click="tutorialVisible=true">
                        <i class="el-icon el-icon-video-play" style="color:#7067FF;font-size: 30px;vertical-align: middle"></i>
                        <span style="text-decoration: underline;margin-left:10px;vertical-align: middle">三分钟快速上手MetaGo</span>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="activeModel === ACTIVE_MODEL.ANSWER_MODEL" id="answerModeScroller" style="overflow-y: auto;" class="leftScroller" :style="{height: scrollerHeight}">
            <!-- margin-top: 20px; -->
            <div style="height: 10px; background-color: transparent;"></div>
            <template-answer-sheet-intro :file-id="fileId" style=""></template-answer-sheet-intro>
            <el-collapse v-model="previewActiveGroups" class="groupQuestionCollapse">

                <el-collapse-item v-for="(group,index) in groupWithUnassigned" :key="group.id" :title="group.name" :name="group.id" v-show="groupVisible[index]">
                    <template #title>
                        <img src="../assets/images/groupMenu.png" class="groupMenuIcon"/>{{group.name}}
                    </template>
                    <doc-question-answer-mode v-for="(answer,j)  in groupAnswerSheets[index]" :key="answer.answer.questionId"
                                              v-show="groupAnswerSheetVisible[index][j]"
                                              :answerSheet="answer" :autoSave="!draftContractMode" :canSearch="true"
                                              :highlightNextRect="highlightNextRect" :highlightPreviousNext="highlightPreviousNext"
                                              @answer-changed="answerChanged"
                                              :isPreview="false"
                                              :showVariable="showVariable">
                    </doc-question-answer-mode>
                </el-collapse-item>
            </el-collapse>

        </div>

    </div>

    <el-dialog
            v-model="tutorialVisible"
            width="800px"
            @opened="videoDialogOpened"
            @closed="videoDialogClosed"
    >
        <video id="tutorialVideo"  src="https://uranus-static.oss-cn-beijing.aliyuncs.com/metago/demo1.mp4" controls="controls" style="width: 750px;"
        >
            您的浏览器不支持 video 标签。
        </video>
    </el-dialog>

    <div class="addOrLinkQuestionDialogOverlay" v-show="addOrLinkQuestionDialogVisible">
        <div class="addOrLinkQuestionDialog">
            <div class="addOrLinkQuestionDialog-header">
                <span class="el-dialog__title">{{addOrLinkQuestionDialogTitle}}</span>
                <button @click="addOrLinkQuestionCancle" aria-label="close" class="el-dialog__headerbtn" type="button"><i class="el-dialog__close el-icon el-icon-close"></i></button>
            </div>
            <!-- :style="{maxHeight: questionDialogBodyMaxHeight}" -->
            <div class="el-dialog__body">
                <el-tabs v-model="addOrLinkQuestionDialogActiveName" @tab-click="addOrLinkQuestionTabClick">
                    <el-tab-pane label="添加" name="add">
                        <doc-question-edit-mode
                            v-if="emptyQuestion !== null"
                            st="dialog"
                            :rt="addOrLinkParam.renderType"
                            :logicDisabled="items.length === 0"
                            :groups="groups"  :groupOrderExchange="groupOrderExchange"
                            :element="emptyQuestion" :controlItems="getControlItems(emptyQuestion)"
                            :key="emptyQuestion.id"
                            @control-question-changed="controlAnsChanged"
                            :deleteQuestionFunc="deleteQuestionFunc" :saveQuestionFunc="saveQuestion"
                            :highlightNextRect="highlightNextRect" :highlightPreviousNext="highlightPreviousNext"
                            :answerNameExistFunc="answerNameExistFunc" :checkQuestionTypeChangeRelatedFunc="checkQuestionTypeChangeRelatedFunc"
                            @question-answer-info-changed="questionAnswerInfoChanged"
                            @question-changed="questionChanged">
                        </doc-question-edit-mode>
                    </el-tab-pane>
                    <el-tab-pane label="关联" name="relate">
                        <div class="question-selection-div">
                            <div style="text-align: center">请选择以下变量进行关联：</div>
                            <div v-for="(group,index) in groupWithUnassigned" :key="group.id" :title="group.name"
                                 :name="group.id" >
                                <div v-if="groupQuestions[index]&&groupQuestions[index].length>0">
                                    <div style="line-height: 30px;">{{group.name}}</div>
                                    <el-radio-group v-model="relatedParentQuestionId" class="addOrLinkQuestionRadioGroup" size="small" @change="relatedQuestionChanged">
                                        <template v-for="item in groupQuestions[index]" :key="item.id">
<!--                                            有子问题的用dropdown显示出来-->
                                            <template v-if="hasChildItemIdSet.has(item.id)">
                                                <el-popover placement="right" trigger="click" v-model:visible="item.extraUIInfo.relatedSubQuestionPopoverVisible">
                                                    <template #reference>
                                                        <el-radio :label="item.id" size="small" border @click.prevent.stop="selectDefaultOrganizationQuestion(item.id)" :title="item.answerName" style="overflow:hidden;">{{truncateWord(item.answerName)}}</el-radio>
                                                    </template>
                                                    <div class="organizationMenuItem" @click="relatedQuestionChanged(item.id)">
                                                        <i class="el-icon el-icon-check" v-if="isRelatedParentQuestion(item.id)"></i>
                                                        <span>{{getParentQuestionDefaultName(item.answerName,item.answerType)}}</span>
                                                    </div>
                                                    <template v-for="child in getChildren(item.id)" :key="child.id">
                                                        <div class="organizationMenuItem" @click="relatedQuestionChanged(item.id,child.id)">
                                                            <i class="el-icon el-icon-check" v-if="isRelatedSubQuestion(item.id,child.id)"></i>
                                                            <span>{{getOrganizationPropertyName(child.answerName)}}</span>
                                                        </div>
                                                    </template>
                                                </el-popover>


                                            </template>
                                            <!--                                            没有子问题的直接将单选框显示出来-->
                                            <template v-else-if="!item.parentId">
                                                <el-radio :label="item.id" size="small" border :title="item.answerName" >{{truncateWord(item.answerName)}}</el-radio>
                                            </template>

                                        </template>
                                    </el-radio-group>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>

            </div>
            <div class="el-dialog__footer" style="text-align: center;margin-bottom:10px;">
                <div v-if="emptyQuestion !== null && addOrLinkParam.renderType === 'bool' && emptyQuestion.extraUIInfo.activeTab === 'basic'" class="dialog-standardAnswer">
                    <div v-if="emptyQuestion.answerType === VARIABLE_TYPE_OPTIONS.TEXT.value || emptyQuestion.answerType === VARIABLE_TYPE_OPTIONS.MULTIPLE_OPTION.value || emptyQuestion.answerType === VARIABLE_TYPE_OPTIONS.ORGANIZATION.value">当变量包含如下值：</div>
                    <div v-else>当变量为：</div>
                    <div class="standardAnswerText" v-if="emptyQuestion.answerType === VARIABLE_TYPE_OPTIONS.TEXT.value || emptyQuestion.answerType === VARIABLE_TYPE_OPTIONS.ORGANIZATION.value"><el-input v-model="standAnswer.text" placeholder="请输入内容"></el-input></div>
                    <div class="standardAnswerBool"  v-else-if="emptyQuestion.answerType === VARIABLE_TYPE_OPTIONS.BOOL.value">
                        <el-select v-model="standAnswer.bool" placeholder="请选择" popper-class="standardAnswerBoolPopper">
                            <el-option label="是" value="1"></el-option>
                            <el-option label="否" value="0"></el-option>
                        </el-select>
                    </div>
                    <div class="standardAnswerSingle"  v-else-if="emptyQuestion.answerType === VARIABLE_TYPE_OPTIONS.SINGLE_OPTION.value">
                        <el-radio-group class="addOrLinkQuestionRadioGroup" v-model="standAnswer.single" size="small">

                            <template v-for="option in emptyQuestion.options" :key="option.id">
                                <el-radio :label="option.name" :value="option" border v-if="!option.desc" :title="option.name"></el-radio>

                                <el-tooltip  class="item" effect="dark" placement="top" v-else>
                                    <el-radio :label="option.name" :value="option" border :title="option.name"></el-radio>
                                    <template #content>
                                        <span v-html="option.desc" style="white-space: pre-wrap"></span>
                                    </template>
                                </el-tooltip>
                            </template>

                        </el-radio-group>
                    </div>
                    <div v-else class="standardAnswerMultiple"  >
                        <el-checkbox-group class="addOrLinkQuestionRadioGroup" v-model="standAnswer.multi" @change="handleCheckedCitiesChange">
                            <template v-for="option in emptyQuestion.options" :key="option.id">
                                <el-checkbox :label="option.name" :value="option" border v-if="!option.desc" :title="option.name"></el-checkbox>
                                <el-tooltip  class="item" effect="dark" placement="top" v-else>
                                    <el-checkbox :label="option.name" :value="option" border :title="option.name"></el-checkbox>
                                    <template #content>
                                        <span v-html="option.desc" style="white-space: pre-wrap"></span>
                                    </template>
                                </el-tooltip>
                            </template>


                        </el-checkbox-group>
                    </div>
                    <div style="margin-top: 10px;">
                        <el-select v-model="standAnswer.showHide" placeholder="请选择">
                            <el-option label="显示关联区域" value="show"></el-option>
                            <el-option label="隐藏关联区域" value="hide"></el-option>
                        </el-select>
                    </div>
                </div>

                <span class="dialog-footer">
                    <el-button @click="addOrLinkQuestionCancle" data-guide-id="cancelButton">取 消</el-button>
                    <el-button type="primary" @click="addOrLinkQuestionSubmit"  data-guide-id="ensureButton">确 定</el-button>
                </span>
            </div>
        </div>
    </div>

</template>

<script>
    import config from "../config";

    // 变量类型，用于选择框
    import {
        deleteQuestion,
        getAllTemplateQuestionByFileId,
        getDefaultAnswerSheetInfo,
        getTemplateQuestionInfoByFileId,
        getTemplateSetting,
        saveQuestionAnswer,
        saveTemplateQuestion,
        saveTemplateQuestionGroup,
        getAiQiChaCompanyData,
        getRecentOrganizations, getGuideInfo, createGuide, markGuide,getSubQuestions
    } from "../api/api";
    import {
        VARIABLE_TYPE_OPTIONS,
        ACTIVE_MODEL,
        EDIT_MODEL,
        questionStrProp2Obj,
        questionObjProp2JSON,
        getQuestionAnswerCacheKey,
        getAnswerCacheMap,
        getQuestionDefaultAnswer,
        produceAnswer,
        answerSheetShouldShowQuestion,
        getOrProduceQuestionDefaultAnswer,
        updateGroupSortIndex,
        COMPANY_BASIC_INFO_MAPPING,
        fixOrganizationQuestionIndex,
        ADD_RELATED_MODE,
        getVisibleDependencyAnswerSheet,
        SHOW_CONTROL_OPTIONS,
        convertContentToStringIFNotNull,
        BOOL_OPTIONS,
        fixAnswerContent,
        SHOW_CONTROL_RELATIONSHIP_OPTIONS
    } from "../constant/question"
    import DocQuestionAnswerMode from "./DocQuestionAnswerMode";
    import draggable from 'vuedraggable'
    import DocQuestionEditMode from "./DocQuestionEditMode";
    import {
        getItemSelectInfo,
        multiple,
        isTextType,
        canTakeTextAsDefaultAnswer,
        single,
        bool,
        singleOrMultiple
    } from "./common/question";
    import {itemJsonStrPro2Obj, MetaMessage} from "../util";
    import TemplateAnswerSheetIntro from "./TemplateAnswerSheetIntro";
    import NoviceGuide from "./NoviceGuide";
    import {ElLoading} from "element-plus";
    const GuideStatus = {
        CREATED:0,
        STARTED:1,
        ERROR:2,
    }

    const metaMessage = new MetaMessage();

    export default {
        name: "DocQuestions",
        props:['fileId','getAssociationQuestionFunc','draftContractMode','deleteQuestionRelatedArea','getAssociationVisibleControlQuestion', 'highlightEditorQuestionRelatedRange', 'leftWidth', 'defaultMode', 'showVariable'],
        components: {
            NoviceGuide,
            TemplateAnswerSheetIntro,
             DocQuestionEditMode, DocQuestionAnswerMode, draggable},
        emits: ["answer-changed", "question-answer-info-changed", "mode-changed", "answer-all-question", 'question-option-changed'],
        data: function () {
            return {
                groupAnswerSheetVisible:[],
                groupVisible:[],
                initDataFinished:false,
                tutorialVisible:false,
                testError: [],
                ACTIVE_MODEL: ACTIVE_MODEL,
                activeModel: this.defaultMode || ACTIVE_MODEL.EDIT_MODEL,
                oldActiveModel: this.defaultMode || ACTIVE_MODEL.EDIT_MODEL,
                EDIT_MODEL: EDIT_MODEL,
                VARIABLE_TYPE_OPTIONS: VARIABLE_TYPE_OPTIONS,
                multiple: multiple,
                isTextType: isTextType,
                getItemSelectInfo: getItemSelectInfo,
                generateQuestionId: -1,
                generateVariableNum: 0,
                // items: [],
                groups: [],
                groupQuestions: [],
                activeGroups: [],
                previewActiveGroups: [],
                UUID_ID_MAP: new Map(),
                ID_UUID_MAP: new Map(),
                QUESTION_MAP: new Map(),
                delayMessageMap: new Map(),
                answerSheet: [],
                dbAnswerSheetId: null,
                addQuestion: false,
                answerSheetId: null,
                addOrLinkParam: null,
                addOrLinkQuestionCallback: null,
                addOrLinkQuestionDialogVisible: false,
                emptyQuestion: null,
                addOrLinkQuestionDialogTitle: '',
                addOrLinkQuestionDialogActiveName: 'add',
                standAnswer: {
                    text: '',
                    bool: null,
                    single: '',
                    multi: [],
                    showHide: 'show'
                },
                relatedParentQuestionId: null,
                relatedSubQuestionId: -1,
                relatedSubQuestionPopoverVisible: false,
                questionRelatedRectHighlightMap: {},
                createAnswerSheetCompleted: false,
                checkAnswerSheetCompletedInterval: null,
                setting: null,
                preDragY: null,
                autoScrollInterval:null,
                dragUpLimitItemIndex: null,// 往上拖动触发限制的问题index
                dragDownLimitItemIndex:null,// 往下拖动触发限制的问题index
                activeQuestionId: null,
                orq: {}, // 保存组织类问题是否正在请求中，最新答案，是否有新的排队请求等信息
                addOrLinkQuestionSubmitFreeze: false,
                guideStatusUpdated:false,
            };
        },
        computed: {
            'answerSheetMap': function () {
                return new Map(this.answerSheet.map(a => [a.question.id, a]))
            },
            'answerNameSet': function () {
                return new Set(this.items.map(item => item.answerName));
            },
            scrollerHeight: function() {
                const height = (window.innerHeight - 60) + 'px';
                return height;
            },
            groupWithUnassigned: function () {
                return  Array.from(this.groups).concat({id:-1,name:'未分组'});
            },
            // groupQuestions:function () {
            //     const groupQuestionMap = new Map();
            //     const questions = this.items;
            //     for (let i = 0; i < questions.length; i++) {
            //         if (!groupQuestionMap.has(questions[i].groupId)) {
            //             groupQuestionMap.set(questions[i].groupId, []);
            //         }
            //         groupQuestionMap.get(questions[i].groupId).push(questions[i]);
            //     }
            //     return this.groups.map(group=>{
            //         const values = groupQuestionMap.get(group.id)
            //         return values ? values : [];
            //     })
            // },
            // noGroupQuestions:function () {
            //     return this.items.filter(item => !item.groupId);
            // },
            groupAnswerSheets: function () {
                const groupAnswerSheetMap = new Map();
                const answerSheets = this.answerSheet
                for (let i = 0; i < answerSheets.length; i++) {
                    let answerSheet = answerSheets[i];
                    const groupId = answerSheet.question.groupId?answerSheet.question.groupId:-1;
                    if (!groupAnswerSheetMap.has(groupId)) {
                        groupAnswerSheetMap.set(groupId, []);
                    }
                    groupAnswerSheetMap.get(groupId).push(answerSheet);
                }
                return this.groupWithUnassigned.map(group=>{
                    const values = groupAnswerSheetMap.get(group.id)
                    return values ? values : [];
                })
            },
            items: function () {
                return this.groupQuestions.flatMap(gq=>gq)
            },
            hasChildItemIdSet: function () {
                let idSet = new Set();
                this.items.forEach(item=>{
                    if (item.parentId) {
                        idSet.add(item.parentId);
                    }
                })
                return idSet;
            }

        },
        mounted() {
            window.Vue = this;
            window.addEventListener('resize', () => {
               this.setQuestionDialogContentMaxHeight();
            });
            this.setQuestionDialogContentMaxHeight();
            this.initGuide();

        },
        watch: {
            // 'items':{
            //     handler: function(newVal,oldVal){
            //         this.refreshQuestionMap();
            //         console.log('items watch', JSON.stringify(newVal), JSON.stringify(oldVal));
            //     },
            //     deep:true
            // },
            'groups':{
                handler: function (newVal, oldVal) {
                    console.log('groups watch',newVal,oldVal)
                    this.startQuestionGroup(this.items)
                },
                deep:true
            },
            'groupQuestions':{
                handler: function (newVal, oldVal) {
                    // console.log('groupQuestions watch', newVal, oldVal)
                    this.fixQuestionInfoByGroupQuestions();
                    this.refreshQuestionMap(this.items);
                },
                deep:true
            },
            'answerSheet':{
                handler: function () {
                    this.reCalculateGroupAnswerSheetVisible()
                },
                deep:true
            },
        },
        methods: {
            truncateWord:function(str){
                if(!str){
                    return str;
                }
                return str.length <= 8 ? str : (str.substring(0, 8) + '...');
            },
            initGuide:function(){
                console.log('init guide***')
                // let steps = [...];
                // this.$refs.guide.init(steps).start()
                if (!this.$route.query.guide) {
                    return;
                }
                getGuideInfo(this.fileId).then(res=>{
                    if (res.data.code === 0) {
                        let guideInfo = res.data.data;
                        this.guideInfo = guideInfo;
                        let guide = JSON.parse(guideInfo.guideJSON);
                        if (guideInfo.reference.guideStatus === 0) {
                            this.$refs.guide.init(guide.steps).start()
                        }else if (guideInfo.reference.guideStatus === 1) {
                            // 教程已经打开过了
                            this.reCreateGuide(guideInfo.reference.sourceFileId);
                        }
                    }
                });
            },
            guideStartedFunc:function(){
                if (this.guideInfo) {
                    markGuide(this.guideInfo.reference.id,GuideStatus.STARTED).then(res=>{
                        this.guideStatusUpdated = true;
                    });
                }
            },
            guideErrorFunc:function(){
                if (this.guideInfo) {
                    markGuide(this.guideInfo.reference.id, GuideStatus.ERROR).then(res=>{
                        this.guideStatusUpdated = true;
                    })
                }
            },
            reCreateGuide:function(sourceFileId){
                this.$confirm('鉴于新手引导已经中断，是否选择重新开始新手引导', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info'
                }).then(() => {
                    let loadingInstance = ElLoading.service({ fullscreen: true, body: true, lock: true, spinner: 'el-icon-loading' });
                    createGuide(sourceFileId).then(res => {
                        let newFiledId = res.data.data.fileId
                        let fileName = res.data.data.fileName;
                        loadingInstance.setText('重新创建新手引导文档成功，即将跳转')
                        window.location.href = config.baseUrl + "/template/doc?fileId=" + newFiledId + "&fileName=" + fileName + "&guide=true";
                    }).catch(error => {
                        console.error(error)
                        loadingInstance.setText('重新开始失败')
                    }).finally(() => {
                        setTimeout(() => loadingInstance.close(), 1000)
                    });
                })
            },
            /**
             * 重新计算那个问题该显示，哪个问题该隐藏
             */
            reCalculateGroupAnswerSheetVisible: function () {
                let sheetSize = this.groupAnswerSheets.length;
                let groupAnswerSheetVisible = [];
                let groupVisible = [];
                for (let i = 0; i < sheetSize; i++) {
                    let groupAnswerSheet = this.groupAnswerSheets[i];
                    let groupQuestionSize = groupAnswerSheet.length;
                    groupAnswerSheetVisible.push([]);
                    let currentGroupVisible = false;
                    for (let j = 0; j < groupQuestionSize; j++) {
                        let answerSheet = this.groupAnswerSheets[i][j];
                        let questionVisible = this.showQuestion(answerSheet);
                        groupAnswerSheetVisible[i].push(questionVisible);
                        if (questionVisible) {
                            currentGroupVisible = true;
                        }
                    }
                    groupVisible.push(currentGroupVisible);
                }
                this.groupAnswerSheetVisible = groupAnswerSheetVisible;
                this.groupVisible = groupVisible;
            },
            videoDialogOpened:function(){
                let tutorialVideo = document.getElementById('tutorialVideo');
                if (tutorialVideo) {
                    tutorialVideo.play();
                }
            },
            videoDialogClosed:function(){
                let tutorialVideo = document.getElementById('tutorialVideo');
                if (tutorialVideo) {
                    tutorialVideo.pause();
                }
            },
            setQuestionDialogContentMaxHeight: function () {
                const maxHeight = window.innerHeight - 450 + 'px';
                const dialogContent = document.querySelector('.addOrLinkQuestionDialog .el-tabs__content');
                if (dialogContent) {
                    dialogContent.style.maxHeight = maxHeight;
                }
            },
            refreshQuestionMap:function(questions){
                let UUID_ID_MAP = new Map();
                let ID_UUID_MAP = new Map();
                let QUESTION_MAP = new Map();
                for (let i = 0; i < questions.length; i++) {
                    UUID_ID_MAP.set(questions[i].uuid,questions[i].id)
                    ID_UUID_MAP.set(questions[i].id,questions[i].uuid)
                    QUESTION_MAP.set(questions[i].id,questions[i])
                }
                this.UUID_ID_MAP = UUID_ID_MAP
                this.ID_UUID_MAP = ID_UUID_MAP
                this.QUESTION_MAP = QUESTION_MAP
            },
            startQuestionGroup:function(questions){
                const groupQuestionMap = new Map();
                for (let i = 0; i < questions.length; i++) {
                    if (!groupQuestionMap.has(questions[i].groupId)) {
                        groupQuestionMap.set(questions[i].groupId, []);
                    }
                    groupQuestionMap.get(questions[i].groupId).push(questions[i]);
                }
                this.groupQuestions = this.groupWithUnassigned.map(group => {
                    const values = groupQuestionMap.get(group.id)
                    return values ? values : [];
                });
            },
            getItems: function () {
                return this.items;
            },
            fixQuestionInfoByGroupQuestions:function(){
                let sortIndex =0;
                this.groupQuestions.forEach((gq,index)=>{
                    gq.forEach(q => {
                        if (q.sortIndex !== sortIndex) {
                            q.sortIndex = sortIndex;
                        }
                        let groupId = this.groupWithUnassigned[index].id === -1 ? null : this.groupWithUnassigned[index].id;
                        if (q.groupId !== groupId) {
                            q.groupId = groupId;
                        }
                        sortIndex++
                    });
                })
            },
            getDomId: function (itemId) {
                if (this.activeModel === ACTIVE_MODEL.EDIT_MODEL) {
                    return 'question-edit-box-' + itemId;
                } else {
                    return 'question-answer-box-' + itemId;
                }
            },
            getUUIDById: function (id) {
                return this.ID_UUID_MAP.get(id);
            },
            getIdByUUID:function(uuid){
                return this.UUID_ID_MAP.get(uuid);
            },
            /**
             * 获取清理掉之后的items（去掉extraUIInfo）
             */
            getCleanedItems: function () {
                return this.items.map(item => {
                    let cleanItem = Object.assign({}, item);
                    delete cleanItem.extraUIInfo;
                    return cleanItem;
                })
            },
            draggedEnd:function(e){
                console.log('draggedEnd:',e)
                // console.log(this.groupQuestions);
                this.preDragY = null;
                this.cancelAutoScroll();
                // 允许将问题拖动到未展开的组里面。
                this.draggedToCollapseGroup(e)
            },
            // 拖动到收起来的组里面
            draggedToCollapseGroup: function (e) {
                let clientX = e.originalEvent.clientX, clientY = e.originalEvent.clientY;
                let groupNodes = document.querySelectorAll(".doc-edit-mode-collapse")
                for (let i = 0; i < groupNodes.length; i++) {
                    // if (groupNodes[i].classList.contains("is-active")) {
                    //     continue;
                    // }
                    let rect = groupNodes[i].getBoundingClientRect();
                    if (rect) {
                        if (rect.x <= clientX && clientX <= rect.x + rect.width && rect.y <= clientY && clientY <= rect.y + rect.height) {
                            // 如果拖拽的落点在收缩起来的组里面。那么就将该问题移动到该组。
                            let questionId = parseInt(e.item.getAttribute('data-question-id'));
                            let question = this.findItemById(questionId);

                            let groupId = parseInt(groupNodes[i].getAttribute('data-group-id'));
                            // console.log('drag ok')
                            this.checkQuestionGroupChangeFunc(question, groupId);
                            return
                        }
                    }
                }
            },
            questionDragover: function (event) {
                // 将禁止拖动的图标转换为可拖动的图标。
                event.preventDefault();
            },
            cancelAutoScroll:function(){
                if (this.autoScrollInterval) {
                    clearInterval(this.autoScrollInterval);
                }
            },
            autoScrollY: function (scroller,delta) {
                this.cancelAutoScroll();
                this.autoScrollInterval = setInterval(()=>{
                    // console.log('auto scroll y');
                    scroller.scroll(scroller.scrollLeft,scroller.scrollTop + delta);
                },30)
            },
            onDrag:function(e){
                // console.log(e.target);
                // const y = e.target.getBoundingClientRect().y;
                const y = e.clientY;
                if (y === 0) {
                    return;
                }
                if (this.preDragY == null) {
                    this.preDragY = y;
                    return;
                }
                const scroller = document.getElementById("editModeScroller")
                const height = e.target.clientHeight
                const speed = 8;
                // console.log('onDrag', y, this.preDragY,height);
                if (y > this.preDragY) {
                    // console.log('scroll down',y,this.preDragY)
                    // 向下拖动
                    const bottom = document.documentElement.clientHeight
                    if (y + height >= bottom) {
                        // 进入向下滚动状态
                        this.autoScrollY(scroller, speed);
                    }else{
                        // 清除滚动状态
                        this.cancelAutoScroll();
                    }
                }else if (y < this.preDragY && y !== 0) {
                    //之所以加上 && y !== 0，是因为当超出下边界的时候y会一瞬间变成0，我们需要去掉这个坏数据。
                    // console.log('scroll up', y, this.preDragY);
                    // 向上拖动
                    const top = document.getElementById("editModeScroller").getBoundingClientRect().y;
                    if (y <= top) {
                        // 进入向上滚动状态
                        this.autoScrollY(scroller, -speed);
                    } else {
                        // 清除滚动状态
                        this.cancelAutoScroll();
                    }
                }
                this.preDragY = y;
            },
            /**
             * 检查两个问题的sortIndex能不能互换
             */
            checkQuestionMove:function(itemIndexA,itemIndexB){
                const minItemIndex=Math.min(itemIndexA,itemIndexB)
                const maxItemIndex=Math.max(itemIndexA,itemIndexB)
                let minItem = this.items[minItemIndex];
                let maxItem = this.items[maxItemIndex];
                if(maxItem.showControlAnsId === minItem.id){
                    return false;
                }
                for (let i = minItemIndex + 1; i < maxItemIndex; i++) {
                    if (this.items[i].showControlAnsId === minItem.id) {
                        // 如果当前问题依赖于minItem，则不允许交换
                        return false;
                    }
                    if (maxItem.showControlAnsId === this.items[i].id) {
                        // 如果移动的最后一个问题依赖于此问提，则不允许交换。
                        return false;
                    }
                }
                return true;
            },
            questionOrderChange: function (event, originalEvent) {
                let draggedItem = event.draggedContext.element
                let relatedItem = event.relatedContext.element
                // console.log('questionOrderChange',draggedItem,relatedItem)
                if (relatedItem == null) {
                    return true;
                }
                let questionIndexMap = this.getQuestionIndexMap();
                let draggedItemIndex = questionIndexMap.get(draggedItem.id);
                let relatedItemIndex = questionIndexMap.get(relatedItem.id);


                if (relatedItem.id === draggedItem.showControlAnsId) {
                    // 往上拖
                    this.showDelayMessage('orderSwitchError','error',`’${draggedItem.answerName}’依赖了’${relatedItem.answerName}’，不允许交换顺序。`,100);
                    return false;
                }else if(draggedItem.id === relatedItem.showControlAnsId){
                    // 往下拖
                    this.showDelayMessage('orderSwitchError','error',`’${relatedItem.answerName}‘依赖了’${draggedItem.answerName}‘，不允许交换顺序。`,100);
                    return false;
                }


                if (!this.checkQuestionMove(draggedItemIndex,relatedItemIndex)) {
                    return false
                }



                // setTimeout(() => {
                //     this.fixQuestionInfoByGroupQuestions();
                // }, 50);
                // let questionIndexMap = this.getQuestionIndexMap();
                // let draggedItemIndex = questionIndexMap.get(draggedItem.id);
                // let relatedItemIndex = questionIndexMap.get(relatedItem.id);
                // draggedItem = this.items[draggedItemIndex];
                // relatedItem = this.items[relatedItemIndex];
                //
                // if (relatedItem.groupId !== draggedItem.groupId&&relatedItem.groupId) {
                //     // 如果不在同一组，则还要吧拖动的元素移动到related元素的那一组去。
                //     draggedItem.groupId = relatedItem.groupId;
                // }else{
                //     // 先交换sortIndex
                //     const relatedItemSortIndex = relatedItem.sortIndex;
                //     relatedItem.sortIndex = draggedItem.sortIndex;
                //     draggedItem.sortIndex = relatedItemSortIndex
                //
                //     // 如果在同一组，只需要更换顺序就行了
                //     // this.items[draggedItemIndex] = relatedItem;
                //     // this.items[relatedItemIndex] = draggedItem
                //     // this.items.splice(draggedItemIndex, 1, relatedItem);
                //     // this.items.splice(relatedItemIndex, 1, draggedItem);
                //
                //     this.refreshGroupQuestions();
                //     // // 这里面之所以对groups进行一次splice操作，是为了触发groups的更改，从而触发界面上的更改
                //     // if (this.groupQuestions && this.groupQuestions.length > 0) {
                //     //     console.log('groupQuestions refresh', this.groups);
                //     //     this.groupQuestions.splice(0,1,this.groupQuestions[0]);
                //     // }
                // }
                // console.log('items:', this.items);
                // console.log('groupQuestions', JSON.stringify(this.groupQuestions));
                return true;



                // let draggedItemSortIndex = draggedItem.sortIndex
                // let relatedItemSortIndex = relatedItem.sortIndex
                // draggedItem.sortIndex = relatedItemSortIndex
                // relatedItem.sortIndex = draggedItemSortIndex
                //不需要单独保存，问题对象发生变化时会自动保存。
                // questionSwitchOrderIndex(draggedItem.id, draggedItemSortIndex, relatedItem.id, relatedItemSortIndex)
                //     .then(res => {
                //         // console.log(res)
                //     })
                //     .catch(err => {
                //         // console.error(err);
                //     });
            },
            /**
             * 延迟提示某消息，如果在delayTime到来之前，同一个key的提示消息再次要求提示，则之前该key的消息不再提示，只提示最新的消息。
             * @param key 用于延迟提示消息的timeout的名字
             * @param level {string} 消息的级别,可选值有success/warning/error/info
             * @param msg {string} 消息内容
             * @param delayTime {number} 延迟时间
             *
             */
            showDelayMessage: function (key, level, msg, delayTime) {
                let timeout = this.delayMessageMap.get(key);
                if (timeout) {
                    clearTimeout(timeout)
                }
                timeout = setTimeout(() => {
                    this.$message({
                        message: msg,
                        type: level
                    })
                }, delayTime);
                this.delayMessageMap.set(key, timeout);
            },
            init: function () {
                if (this.activeModel === ACTIVE_MODEL.EDIT_MODEL) {
                    this.refreshData(this.fileId)
                }
                // 默认是预览模式的时候不能先调用createAnswerSheet(),要等editorjs渲染完毕之后，查询出了关联的问题，才可以创建答题板
                // else {
                //     this.createAnswerSheet();
                // }
            },
            switchActiveModel: function (tab) {
                if (tab.paneName !== this.oldActiveModel) {
                    this.oldActiveModel = tab.paneName;
                    this.$emit('mode-changed', tab.paneName);
                }
            },
            activeModelSwitched:function(mode){
                this.activeModel = mode;
                switch (mode) {
                    case ACTIVE_MODEL.EDIT_MODEL:
                        this.refreshData(this.fileId);
                        this.answerSheet =[]
                        return
                    case ACTIVE_MODEL.ANSWER_MODEL:
                        this.createAnswerSheet();
                }
            },
            refreshEditorQuestionInfo:function(){
                switch (this.activeModel) {
                    case ACTIVE_MODEL.EDIT_MODEL:
                        this.$emit('refresh-editor-questions',this.items);
                        return
                    case ACTIVE_MODEL.ANSWER_MODEL:
                        const questions = this.answerSheet.map(answerSheet => Object.assign({},answerSheet.question));
                        console.log('refresh editor question info,questions:', questions);
                        this.$emit('refresh-editor-questions', questions);
                        return
                }
            },
            answerAllQuestion: function(callBack) {
                this.checkAnswerSheetCompletedInterval = setInterval(() => {
                    if (this.createAnswerSheetCompleted) {
                        clearInterval(this.checkAnswerSheetCompletedInterval);
                        this.doAnswerAllQuestion(callBack);
                    }
                }, 1000)
            },
            doAnswerAllQuestion: function (callBack) {
                const answers = [];
                const answerSubQuestions = [];
                for (let j = 0; j < this.answerSheet.length; j++) {
                    const item = this.answerSheet[j];
                    if(!item.answer.id){
                        this.saveChangedAnswer(item, j);
                    }

                    const answerSheet = { "answer": item };
                    let visibleDependencyAnswerSheet = getVisibleDependencyAnswerSheet(answerSheet.answer.question, this.groupAnswerSheets);
                    let involvedQuestionVisible = [];
                    for (let i = 0; i < visibleDependencyAnswerSheet.length; i++) {
                        let questionVisible = answerSheetShouldShowQuestion(visibleDependencyAnswerSheet[i], this.answerSheetMap);
                        involvedQuestionVisible.push({uuid:visibleDependencyAnswerSheet[i].questionUUID,visible:questionVisible})
                    }
                    answers.push({
                        questionUUID: answerSheet.answer.questionUUID,
                        answer: answerSheet.answer.answer.content,
                        subQuestionsVisible: involvedQuestionVisible
                    });
                    if (!answerSheet.answer.question.parentId && answerSheet.answer.question.answerType === VARIABLE_TYPE_OPTIONS.ORGANIZATION.value) {
                        // 回答的组织名称
                        const questionId = answerSheet.answer.question.id;
                        // 查询爱企查，回答相关子问题
                        // const answerContent = answerSheet.answer.answer.content;
                        answerSubQuestions.push({
                          "answerSheet": answerSheet,
                          "questionId": questionId
                        })
                        // this.requestAiQiChaAndAnswerSubQuestions(answerSheet, questionId);
                    }
                }
                this.$emit('answer-all-question', answers);
                for (const item of answerSubQuestions) {
                  this.requestAiQiChaAndAnswerSubQuestions(item['answerSheet'], item['questionId']);
                }
                callBack && callBack();
            },

            saveChangedAnswer: function (item, i) {
                if (this.draftContractMode) {
                    // 起草模式不保存答案。
                    return;
                }
                //如果是一个新的答案，则需要保存到数据库
                let saveAnswer = Object.assign({}, item.answer)
                //将结果转换成字符串再提交
                saveAnswer.content = convertContentToStringIFNotNull(saveAnswer.content);
                saveQuestionAnswer(saveAnswer).then(res=>{
                    //将接口返回的信息更新到界面的答案对象中，但保留界面的答案对象的content字段（不用再转一次）。
                    let useAnswer = res.data.data
                    useAnswer.content = item.answer.content
                    this.answerSheet[i].answer = useAnswer;
                })
            },
            getControlItems: function (currentItem) {
                return this.items.filter((item) => item.sortIndex < currentItem.sortIndex && !item.parentId);
            },
            /**
             * 检查是否有问题 逻辑依赖于 questionId对应的问题
             * @param question
             * @param hasTextDependencyTip
             * @param hsaQuestionDependencyTip
             */
            checkIdDependencyFunc: function (question,needHighlightReladedRect,hasTextDependencyTip,hsaQuestionDependencyTip) {
                hasTextDependencyTip = hasTextDependencyTip ? hasTextDependencyTip : '已有文本关联了该问题(显隐控制)。';
                hsaQuestionDependencyTip = hsaQuestionDependencyTip ? hsaQuestionDependencyTip : '尚有其它问题依赖于本问题。';

                if (this.checkUUIDDependencyFunc(question.uuid)) {
                    //如果当前问题已经关联了区域
                    this.$message.error(hasTextDependencyTip)
                    if (needHighlightReladedRect) {
                        this.highlightEditorQuestionRelatedRange(question.uuid, true);
                    }
                    // this.$emit('highlight-editor-question-related-range', { questionUUID: question.uuid, index: -1 })
                    return true
                }
                let relatedItems = this.getRelatedQuestionFunc(question.id);
                if(relatedItems.length>0){
                    //切换为逻辑板块
                    this.focusQuestionAndSwitchLogicModel(relatedItems[0])
                    this.$message.warning(hsaQuestionDependencyTip)
                    return true
                }
                return false;
            },
            checkQuestionFullDependencyFunc:function(question){
                const result = {
                    hasDependency:false,
                    questionTextDependency:false,
                    questionDependency:false,
                    relatedQuestions:[],
                    subQuestions: []
                }
                if (this.checkUUIDDependencyFunc(question.uuid)) {
                    result.questionTextDependency = true;
                    result.hasDependency = true;
                }
                let relatedItems = this.getRelatedQuestionFunc(question.id);
                if (relatedItems.length > 0) {
                    result.questionDependency = true;
                    result.hasDependency = true;
                    result.relatedQuestions = relatedItems;
                }
                const haveSubQuestions = question.answerType===VARIABLE_TYPE_OPTIONS.ORGANIZATION.value
                if (haveSubQuestions) {
                    let subQuestions = this.getChildren(question.id);
                    for (let i = 0; i < subQuestions.length; i++) {
                        if (this.checkUUIDDependencyFunc(question.uuid)){
                            result.questionTextDependency = true;
                            result.hasDependency = true;
                        }
                    }
                    result.subQuestions = subQuestions;
                }
                return result;
            },
            checkQuestionTypeChangeRelatedFunc:function(question){
                let result = {
                    hasDependency:false,
                    questionTextDependency:false,
                    questionDependency:false,
                };
                if (question.answerType === VARIABLE_TYPE_OPTIONS.ORGANIZATION.value) {
                    // 如果是组织结构变量,需要检查全依赖，包括他的子问题。
                    return Object.assign(result, this.checkQuestionFullDependencyFunc(question));
                }
                if (this.checkUUIDVisibleDependencyFunc(question.uuid)) {
                    result.questionTextDependency = true;
                }
                let relatedItems = this.getRelatedQuestionFunc(question.id);
                if(relatedItems.length>0){
                    result.questionDependency = true;
                }
                result.hasDependency = result.questionTextDependency || result.questionDependency;
                return result
            },
            checkQuestionGroupChangeFunc:function(question,newGroupId){
                if (question.groupId === newGroupId) {
                    // console.log('无需修改，组一样');
                    return true;
                }
                const groupIdIndexMap = this.getGroupIdIndexMap();
                const questionIdIndexMap = this.getQuestionIndexMap();


                // 同一组（与目标组相同）内依赖于它的问题的最小index
                let sameGroupMinRelatedItItemIndex = null, sameGroupMinRelatedItItem = null;
                //检查目标组 是否在依赖于它的问题的组 前面
                let relatedItQuestions = this.getRelatedQuestionFunc(question.id);
                const newGroupIndex = groupIdIndexMap.get(newGroupId);
                for (let i = 0; i < relatedItQuestions.length; i++) {
                    const groupIndex = groupIdIndexMap.get(relatedItQuestions[i].groupId)
                    // 如果依赖于它的question所在的组还在这个新的组前面，则该操作是不被允许的。
                    // 对于有的旧数据，没有groupId，则不用检查
                    if (groupIndex !== undefined ) {
                        if (newGroupIndex > groupIndex) {
                            this.$message.error(`'${relatedItQuestions[i].answerName}'依赖于它的问题所在组之后`);
                            this.focusQuestionById(relatedItQuestions[i].id)
                            return false;
                        }else if (newGroupIndex === groupIndex) {
                            sameGroupMinRelatedItItemIndex = Math.min(sameGroupMinRelatedItItemIndex, questionIdIndexMap.get(relatedItQuestions[i].id));
                            if (sameGroupMinRelatedItItem === questionIdIndexMap.get(relatedItQuestions[i].id)) {
                                sameGroupMinRelatedItItem = relatedItQuestions[i];
                            }
                        }

                    }
                }
                // 它所依赖的问题的位置
                let sameGroupItRelatedItemIndex = null,sameGroupItRelatedItem = null;
                // 检查目标组 是否在它所依赖的问题的组 后面，
                if (question.showControlAnsId) {
                    let itRelatedQuestion = this.findItemById(question.showControlAnsId);
                    let itRelatedGroupIndex = groupIdIndexMap.get(itRelatedQuestion.groupId);
                    if (itRelatedGroupIndex !== undefined) {
                        if (itRelatedGroupIndex > newGroupIndex) {
                            this.$message.error('不允许新设置组在它所依赖的问题所在组之前');
                            this.focusQuestionById(itRelatedQuestion.id)
                            return false;
                        }else if (itRelatedGroupIndex === newGroupIndex) {
                            //如果处于同一组
                            sameGroupItRelatedItemIndex = questionIdIndexMap.get(question.showControlAnsId)
                            sameGroupItRelatedItem = itRelatedQuestion;
                        }
                    }
                }
                const oldGroupId = question.groupId;
                question.groupId = newGroupId;
                // 如果检查都过了，则开始设置
                // 移动question在数组中位置，需要满足在它所依赖的问题之后，在所有依赖于它的问题之前。

                // 这里需要注意的是sameGroupItRelatedItemIndex 和 sameGroupMinRelatedItItemIndex不可能同时都有值，
                // 因为，如果它们同时都有值说明这个问题原来所在组就是目标组，根本不会走到这里来。
                if (sameGroupMinRelatedItItemIndex !== null) {
                    //如果有groupId则直接移动到该组的最前面，如果没有，说明是旧数据，就移动到它依赖的问题之后
                    oldGroupId ? this.moveQuestionToGroupHead(question,newGroupId) : this.moveQuestionBefore(question,sameGroupMinRelatedItItem);
                }else if (sameGroupItRelatedItemIndex !== null) {
                    //如果有groupId则直接移动到该组的最后面，如果没有，说明是旧数据，就移动到最近的依赖于它的问题之前。
                    oldGroupId ? this.moveQuestionToGroupTail(question,newGroupId) : this.moveQuestionAfter(question,sameGroupItRelatedItem);
                }else{
                    let oldGroupIndex = groupIdIndexMap.get(oldGroupId);
                    if (oldGroupIndex !== undefined && newGroupIndex !== undefined) {
                        newGroupIndex > oldGroupIndex ? this.moveQuestionToGroupHead(question,newGroupId) : this.moveQuestionToGroupTail(question,newGroupId);
                    }else {
                        this.moveQuestionToGroupTail(question,newGroupId)
                    }
                }
                this.focusQuestionById(question.id)
                // 重新更新问题的sortIndex
                // this.fixQuestionInfoByGroupQuestions()
                return true;

            },
            groupOrderExchange: function (groupIndex1, groupIndex2) {
                let minGroupIndex = Math.min(groupIndex1, groupIndex2);
                let maxGroupIndex = Math.max(groupIndex1, groupIndex2);

                const frontGroup = this.groups[minGroupIndex];
                const backendGroup = this.groups[maxGroupIndex];
                let questions2 = this.groupQuestions[maxGroupIndex];
                let questionIndexMap = this.getQuestionIndexMap();
                for (let i = 0; i < questions2.length; i++) {
                    if(questions2[i].showControlAnsId){
                        let relatedItem = this.items[questionIndexMap.get(questions2[i].showControlAnsId)];
                        if (relatedItem.groupId === frontGroup.id) {
                            // 如果该问题所依赖的问题刚好在前面需要交换的组里面，则不允许交换。
                            this.$message.error(`'${backendGroup.name}'组 的问题 '${questions2[i].answerName}' 依赖了 '${frontGroup.name}'组 的问题 '${relatedItem.answerName}'，无法交换。`)
                            return false;
                        }
                    }
                }
                // 将两个group进行交换
                this.groups[minGroupIndex] = this.groups[maxGroupIndex];
                this.groups[maxGroupIndex] = frontGroup;
                // 重新指定每一个group的sortIndex
                let updateGroups = updateGroupSortIndex(this.groups);

                // 将变化了的group保存到数据库
                if (updateGroups.length > 0) {
                    updateGroups.forEach(group=>{
                        saveTemplateQuestionGroup(group);
                    })
                }
                return true;
            },
            moveQuestionToGroupHead: function (question,groupId) {
                // const newItems = [];
                // let matched = false;
                // // for (let i = 0; i < this.items.length; i++) {
                // //     let item = this.items[i];
                // //     if (groupId === item.groupId&&!matched) {
                // //         newItems.push(question);
                // //         matched = true;
                // //     }
                // //     if (item.id === question.id) {
                // //         continue
                // //     }
                // //     newItems.push(item)
                // // }
                // this.items = newItems;
                this.groupQuestions.forEach((gq,i)=>{
                    this.groupQuestions[i] = gq.filter(q => q.id !== question.id);
                    if (this.groupWithUnassigned[i].id === groupId) {
                        this.groupQuestions[i].splice(0, 0, question);
                    }
                })
            },
            moveQuestionToGroupTail:function(question,groupId){
                // const newItems = [];
                // let matched = false;
                // for (let i = this.items.length-1; i >= 0; i--) {
                //     let item = this.items[i];
                //     if (groupId === item.groupId&&!matched) {
                //         newItems.push(question);
                //         matched = true;
                //     }
                //     if (item.id === question.id) {
                //         continue
                //     }
                //     newItems.push(item);
                // }
                // this.items = newItems.reverse();

                this.groupQuestions.forEach((gq,i)=>{
                    this.groupQuestions[i] = gq.filter(q => q.id !== question.id);
                    if (this.groupWithUnassigned[i].id === groupId) {
                        this.groupQuestions[i].push(question);
                        // this.groupQuestions[i].splice(this.groupQuestions[i].length, 0, question);
                    }
                })
            },
            moveQuestionBefore: function (question, beforeQuestion) {
                this.groupQuestions.forEach((gq,i)=>{
                    let newGroupQuestion = [];
                    gq.forEach(q => {
                        if(q.id === beforeQuestion.id){
                            newGroupQuestion.push(question);
                        }
                        // 将question从原来位置过滤
                        if (q.id !== question.id) {
                            newGroupQuestion.push(q);
                        }
                    });
                    this.groupQuestions[i] = newGroupQuestion
                })
            },
            moveQuestionAfter: function (question, afterQuestion) {
                this.groupQuestions.forEach((gq,i)=>{
                    let newGroupQuestion = [];
                    gq.forEach(q => {
                        // 将question从原来位置过滤
                        if (q.id !== question.id) {
                            newGroupQuestion.push(q);
                        }
                        if(q.id === afterQuestion.id){
                            newGroupQuestion.push(question);
                        }
                    });
                    this.groupQuestions[i] = newGroupQuestion
                })
            },
            // moveQuestionToIndex:function(question,newIndex){
            //     // let newItems = [];
            //     // newItems = newItems.concat(this.items.slice(0, newIndex).filter(item => item.id !== question.id));
            //     // newItems.push(question);
            //     // newItems = newItems.concat(this.items.slice(newIndex, this.items.length).filter(item => item.id !== question.id));
            //     // this.items = newItems;
            // },
            getGroupIdIndexMap:function(){
                const groupIdIndexMap = new Map();
                for (let i = 0; i < this.groups.length; i++) {
                    groupIdIndexMap.set(this.groups[i].id, i);
                }
                return groupIdIndexMap;
            },
            getQuestionIndexMap:function(){
                const questionIdIndexMap = new Map();
                for (let i = 0; i < this.items.length; i++) {
                    questionIdIndexMap.set(this.items[i].id, i);
                }
                return questionIdIndexMap;
            },
            getRelatedQuestionFunc:function(questionId){
                let dependencyItems = []
                for (let i = 0; i < this.items.length; i++) {
                    let item = this.items[i];
                    if (item.showControlAnsId === questionId) {
                        dependencyItems.push(item);
                    }
                }
                return dependencyItems;
            },
            /**
             * 检查是否有文本关联了该区域,并且设置的是显隐控制。
             */
            checkUUIDVisibleDependencyFunc:function(questionUUID){
                // let associatedQuestionUUID = this.getAssociationQuestionFunc()
                let associatedQuestionUUID = this.getAssociationVisibleControlQuestion()
                return associatedQuestionUUID && associatedQuestionUUID.indexOf(questionUUID) >= 0;
            },
            /**
             * 检查是否有文本管理了该区域，与上一个方法区别是不一定是显隐控制，也可以是文本变量
             */
            checkUUIDDependencyFunc: function(questionUUID){
                let associatedQuestionUUID = this.getAssociationQuestionFunc()
                return associatedQuestionUUID && associatedQuestionUUID.indexOf(questionUUID) >= 0;
            },
            refreshData: function (fileId) {
                return getTemplateQuestionInfoByFileId(fileId).then(res => {
                    if (res.data.code !== 0) {
                        this.$message.error('获取模板问题失败，请稍后再试')
                        return;
                    }
                    const questions = res.data.data.questions;
                    this.translateQuestions(questions);
                    this.groups = res.data.data.groups;
                    // this.items = questions;
                    this.startQuestionGroup(questions)
                    // console.log('refreshData:',JSON.stringify(this.items))
                    this.activeGroups = this.groups.map(group => group.id);
                    this.activeGroups.push(-2)
                    // console.log('groups:', this.groups);
                }).catch(err => {
                    this.$message.error('获取模板问题失败，请稍后再试')
                    console.error(err);
                }).finally(()=>{
                    this.initDataFinished = true;
                });
            },
            translateQuestions: function(questions) {
                const questionMap = new Map(questions.map((question) => [question.id, question]));
                for (let i = 0; i < questions.length; i++) {
                    //将json字符串属性解析为对象
                    questionStrProp2Obj(questions[i]);
                    if (questions[i].showControlAnsId) {
                        //如果它的显隐取决于其它问题
                        //生成默认的ui信息
                        questions[i].extraUIInfo = this.produceQuestionExtraUIInfo(questionMap.get(questions[i].showControlAnsId));
                    } else {
                        //生成默认的ui信息
                        questions[i].extraUIInfo = this.produceQuestionExtraUIInfo();
                    }
                    // questions[i].defaultAnswer = getQuestionDefaultAnswer(questions[i], true);
                    if (questions[i].title.length > 0) {
                        questions[i].extraUIInfo.titleChange = true;
                    }
                    // 将老数据中的options转换为新结构
                    if(questions[i].options){
                        questions[i].options = questions[i].options.map(option => {
                            if (typeof option === "object") {
                                return option
                            }
                            return {id: option, name: option, desc: ''};
                        });
                    }
                }
            },
            editorInitReady:function(){
              /**
               * 这里的activeModel以前是activeMode
               */
              if (this.activeModel === ACTIVE_MODEL.ANSWER_MODEL) {
                    this.createAnswerSheet().then(()=>{
                        this.doAnswerAllQuestion()
                        this.refreshEditorQuestionInfo()
                    });
              }else{
                  // this.reCalculateQuestionAnswerTypeDisabled();
                  this.refreshEditorQuestionInfo()
              }
            },

            editorContentChanged:function(){
                // 当关联文本发生变化时，重新计算哪些问题不允许更改答案类型
                if (this.editorContentChangedTimeout) {
                    clearTimeout(this.editorContentChangedTimeout);
                }
                // this.editorContentChangedTimeout = setTimeout(()=>{
                //     this.reCalculateQuestionAnswerTypeDisabled();
                // },300);
            },
            /**
             * 创建答卷
             */
            createAnswerSheet: function () {
                const self = this;
                self.createAnswerSheetCompleted = false;
                //获取缓存的答卷信息
                return getDefaultAnswerSheetInfo(this.fileId).then(res=>{
                    this.groups = res.data.data.groups;
                    const answerSheetInfo = res.data.data
                    //获取到所有问题的答案缓存
                    let answers = answerSheetInfo.answers
                    // 如果是起草合同模块，则不需要默认的answers
                    if (this.draftContractMode) {
                        answers = [];
                    }
                    const answerSheetId = answerSheetInfo.sheet.id;
                    const questionAnswerMap = getAnswerCacheMap(answers);
                    let ids = this.getAssociationQuestionFunc()
                    //将所有id转为String（似乎editorJs返回既可能是字符串有可能是数字），便于判断是否存在。
                    let idSet = new Set(ids.map(s=>String(s)));
                    const questions = fixOrganizationQuestionIndex(res.data.data.questions);
                    this.translateQuestions(questions);
                    console.log('create AnswerSheet******')
                    console.log(questions);
                    console.log('create AnswerSheet******')
                    self.answerSheet = questions.filter(item => idSet.has(String(item.uuid))).map((item) => {
                        let answer = questionAnswerMap.get(getQuestionAnswerCacheKey(item.id, item.answerType));
                        if (answer) {
                            itemJsonStrPro2Obj(answer,'content');
                            if (typeof answer.content === "number") {
                                answer.content = JSON.stringify(answer.content);
                            }
                            // self.fixDefaultValueIfNeed(item,answer);
                            fixAnswerContent(item,answer);
                            // 设置默认答案。（如果切换到预览模式之后设置的默认答案，就需要将默认答案设置到答案里面）
                            if ((answer.content == null || answer.content === '') && item.defaultAnswer != null) {
                                answer.content = getOrProduceQuestionDefaultAnswer(item, true);
                            }
                        }else {
                            // answer = produceAnswer(item.answerType, getQuestionDefaultAnswer(item,false), item.id, answerSheetId,self.fileId);
                            answer = produceAnswer(item.answerType, getOrProduceQuestionDefaultAnswer(item,true), item.id, answerSheetId,self.fileId);
                        }
                        item.extraUIInfo.defaultShow = !item.parentId
                        return {
                            question: item,
                            questionUUID:item.uuid,
                            answer: answer
                        };
                    });
                    self.previewActiveGroups = this.groupWithUnassigned.map(group => group.id);
                    self.createAnswerSheetCompleted = true;
                })
            },
            //插入一个问题的默认答案
            insertAnswerCache:function(question,content){
                const self = this;
                this.getAnswerSheetId().then(answerSheetId => {
                    const defaultVal = this.isTextType(question) ? content : getQuestionDefaultAnswer(question,false);
                    let answer = produceAnswer(question.answerType, defaultVal, question.id, answerSheetId, self.fileId);
                    saveQuestionAnswer(answer)
                }).catch(error => {
                    this.$message.error('保存默认答案失败')
                    console.error(error);
                });
            },

            getAnswerSheetId: function () {
                let self = this
                return new Promise((resolve,reject) => {
                    if (self.answerSheetId) {
                        return resolve(self.answerSheetId)
                    }
                    getDefaultAnswerSheetInfo(self.fileId).then(res => {
                        const answerSheetInfo = res.data.data
                        //获取到所有问题的答案缓存
                        const answerSheetId = answerSheetInfo.sheet.id
                        self.answerSheetId = answerSheetId;
                        return resolve(answerSheetId);
                    }).catch(error => {
                        reject(error);
                    });
                })
            },
            //是否显示某个问题
            showQuestion:function(answerSheet){
                // console.log('************new show question*****')
                return answerSheet.question.extraUIInfo.defaultShow && answerSheetShouldShowQuestion(answerSheet, this.answerSheetMap);
            },
            /**
             * 添加一个空的question待用户修改
             */
            addDefaultQuestion: function () {
                this.newAndSaveQuestion();
                // this.items.push(this.produceQuestion())
            },
            /**
             * 新建一个question并立即保存
             * @param {{answerType}} questionDefaultVal
             */
            newAndSaveQuestion: function (questionDefaultVal) {
                this.addQuestion = true;
                let newQuestion = this.produceQuestion();
                if (questionDefaultVal) {
                    Object.assign(newQuestion, questionDefaultVal);
                }

                if (newQuestion.answerName.length === 0) {
                    this.$message.error('变量名不能为空');
                    return newQuestion;
                }

                return this.saveQuestion(newQuestion).then(res => {
                    if (res.data.code === 0) {
                        newQuestion.id = res.data.data.id;
                        newQuestion.uuid = res.data.data.uuid;
                        this.addQuestion = false;
                        this.pushQuestion(newQuestion);
                        // this.items.push(newQuestion)
                        this.$nextTick(() => {
                            this.focusQuestionById(newQuestion.id)
                        })
                    } else if (res.data.code === 409) {
                        metaMessage['error']('变量名已存在');
                    } else {
                        metaMessage['error']('保存问题失败');
                    }
                    return newQuestion
                }).catch(err => {
                    metaMessage['error']('添加/保存问题失败');
                    console.error('添加/保存问题失败  err:', err)
                });
            },
            /**
             * 更新一个问题依赖的问题
             */
            updateDefaultRelatedQuestion: function (questionUUID, relatedQuestionUUID) {
                let question = this.findItemByUUID(questionUUID);
                if (question.showControlAnsId !== null) {
                    return false
                }
                question.showControlAnsId = this.getIdByUUID(relatedQuestionUUID);
                return true;
            },
            /**
             * 检查questionId对应问题是否有逻辑依赖问题
             * @return {boolean}
             */
            checkQuestionHasRelatedQuestion: function (questionUUID) {
                let question = this.findItemByUUID(questionUUID);
                return question.showControlAnsId !== null;
            },
            focusQuestionById: function (id) {
                let question = this.findItemById(id);
                if (question) {
                    this.focusQuestion(question);
                }
            },
            focusQuestionByUUID:function(uuid){
                if (this.activeModel === ACTIVE_MODEL.EDIT_MODEL) {
                    let question = this.findItemByUUID(uuid);
                    if (question) {
                        this.focusQuestion(question);
                    }
                } else {
                    // 高亮答题框
                    this.focusAnswerBox(uuid);
                }
            },
            focusQuestion: function (question) {
                // 如果需要展开，则展开该组
                if (this.activeGroups.indexOf(question.groupId) < 0 && question.groupId) {
                    const newActiveGroups = Array.from(this.activeGroups);
                    newActiveGroups.push(question.groupId);
                    this.activeGroups = newActiveGroups;
                }
                this.$nextTick(()=>{
                    let questionBox = document.getElementById(this.getDomId(question.id))
                    if (questionBox) {
                        questionBox.scrollIntoView({behavior: 'smooth', block: "center", inline: "nearest"});
                        this.twinkleQuestion(question)
                    }
                });
            },
            focusAnswerBox: function (uuid) {
                let answerSheet = this.findAnswerSheetByUUID(uuid);
                if (answerSheet) {
                    let answerSheetBox = document.getElementById(this.getDomId(answerSheet.question.id))
                    if (answerSheetBox) {
                        answerSheetBox.scrollIntoView({behavior: 'smooth', block: "center", inline: "nearest"});
                        this.twinkleQuestion(answerSheet.question);
                    }
                }
            },
            focusQuestionAndSwitchLogicModel:function(question){
                question.extraUIInfo.activeTab = EDIT_MODEL.LOGIC;
                this.focusQuestion(question)
            },
            /**
             * 让问题框闪烁一下
             */
            twinkleQuestion: function (question) {
                return new Promise((resolve => {
                    setTimeout(() => question.extraUIInfo.twinkle = true, 100);
                    setTimeout(() => {
                        question.extraUIInfo.twinkle = false
                        resolve()
                    }, 850);
                }))
            },
            deleteQuestionDependency:function({question,onlyVisibleControlArea=true,callback}){
                let deleteRelatedAreaParams = [];
                // 删除子问题与文本的关联。
                let subQuestions = this.getChildren(question.id);
                if (subQuestions.length > 0) {
                    deleteRelatedAreaParams = subQuestions.map(q => {return {questionUUID: q.uuid, onlyDeleteBoolVariable: onlyVisibleControlArea}});
                }
                // 删除本问题与文本的关联。
                deleteRelatedAreaParams.push({questionUUID:question.uuid, onlyDeleteBoolVariable: onlyVisibleControlArea})
                this.deleteQuestionRelatedArea(deleteRelatedAreaParams);

                // 删除逻辑板块依赖于该问题的问题
                let relatedItems = this.getRelatedQuestionFunc(question.id);
                if (relatedItems.length > 0) {
                    relatedItems.forEach(item=>{
                        item.showControlAnsId = null;
                        item.showControlAnsVal =null;
                        item.showControlAction = null;
                        item.showControlRelationshipType = SHOW_CONTROL_RELATIONSHIP_OPTIONS.VALUE_EQ.value;
                    })
                }

                // 执行回调
                if (callback) {
                    callback();
                }
            },
            getDeleteDependencyTip:function(checkResult){
                let title = '即将删除该问题, 是否继续?';
                if (checkResult.hasDependency) {
                    let useStr = [],actionStr = [];
                    if (checkResult.questionTextDependency) {
                        useStr.push('绑定区域');
                        actionStr.push("区域绑定关系")
                    }
                    if (checkResult.questionDependency) {
                        useStr.push('依存的问题')
                        actionStr.push("问题的依存关系")
                    }
                    title = `由于该问题已经存在${useStr.join("与")}，删除此问题时会自动删除${actionStr.join("及")}`
                }
                return title;
            },
            /**
             * 删除某个问题
             */
            deleteQuestionFunc: function (question) {
                // if (this.checkIdDependencyFunc(question, true,'文档中已有内容与此问题关联，因此无法删除本问题。', '尚有其它问题依赖于本问题。因此无法删除本问题。')) {
                //     return;
                // }
                let checkResult = this.checkQuestionFullDependencyFunc(question);

                let title = this.getDeleteDependencyTip(checkResult);
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteQuestion(question.fileId,question.id).then(res => {
                        if (res.data.code === 0) {
                            let children = this.getChildren(question.id);
                            question.extraUIInfo.deleted = true;
                            this.twinkleQuestion(question).then(()=>{
                                // this.removeQuestionById(question.id);
                                this.deleteQuestionDependency({question:question,onlyVisibleControlArea:false})
                                this.removeQuestions([question].concat(children))
                            })
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }else{
                            this.$message({
                                type: 'error',
                                message: res.data.msg
                            });
                        }
                    });
                });


            },
            removeQuestionById: function (id) {
                // for (let i = 0; i < this.items.length; i++) {
                //     if (this.items[i].id === id) {
                //         this.items.splice(i, 1);
                //     }
                // }
                for (let i = 0; i < this.groupQuestions.length; i++) {
                    for (let j = 0; j < this.groupQuestions[i].length; j++) {
                        if (this.groupQuestions[i][j].id === id) {
                            this.groupQuestions[i].splice(j, 1);
                            return;
                        }
                    }
                }
            },
            removeQuestions:function(questions){
                let newGroupQuestions = [];
                let questionIdSet = new Set();
                for (let i = 0; i < questions.length; i++) {
                    questionIdSet.add(questions[i].id);
                }
                for (let i = 0; i < this.groupQuestions.length; i++) {
                    newGroupQuestions.push([]);
                    for (let j = 0; j < this.groupQuestions[i].length; j++) {
                        if (!questionIdSet.has(this.groupQuestions[i][j].id)) {
                            newGroupQuestions[i].push(this.groupQuestions[i][j])
                        }
                    }
                }
                this.groupQuestions = newGroupQuestions;
            },
            pushQuestion: function (question) {
                for (let i = 0; i < this.groupQuestions.length; i++) {
                    if (this.groups[i].id === question.groupId) {
                        this.groupQuestions[i].push(question);
                        return;
                    }
                }
            },
            deleteSubQuestions: function (question) {
              for (let i = 0; i < this.groupQuestions.length; i++) {
                if (this.groups[i].id === question.groupId) {
                  const newQuestions = [];
                  for (const q of this.groupQuestions[i]) {
                    if (q.parentId !== question.id) {
                      newQuestions.push(q);
                    }
                  }
                  this.groupQuestions[i] = newQuestions;
                  break;
                }
              }
            },
            highlightNextRect: function (question) {
                const index = this.questionRelatedRectHighlightMap[question.uuid] || 0;
                this.highlightEditorQuestionRelatedRange(question.uuid, false, 'next', index).then(res => {
                    this.questionRelatedRectHighlightMap[question.uuid] = res;
                });
            },
            highlightPreviousNext: function (question) {
                const index = this.questionRelatedRectHighlightMap[question.uuid] || 0;
                this.highlightEditorQuestionRelatedRange(question.uuid, false, 'previous', index).then(res => {
                    this.questionRelatedRectHighlightMap[question.uuid] = res;
                });
            },
            /**
             * 问题列表里面是否存在其他问题的answerName与该问题的answerName一样。
             * @param id 该问题的id
             * @param answerName 该问题的answerName
             */
            answerNameExistFunc:function(id,answerName){
                return this.items.some(item => {
                    if (item.id === id) {
                        return false;
                    }
                    if(item.answerName === answerName){
                        return true;
                    }
                    if (item.answerType === VARIABLE_TYPE_OPTIONS.ORGANIZATION.value && !item.parentId) {
                        // 如果是组织结构父问题，则还要比较它加上默认后缀后的名字。
                        return this.getParentQuestionHiddenVariableName(item.answerName,item.answerType) === answerName;
                    }
                    return false;
                });
            },
            /**
             * 生成一个空的问题
             * @param {number} sortIndex 指定问题的sortIndex，如不指定则用最后一个问题的sortIndex+1
             * @param {boolean} autoGenerateAnswerName 是否需要
             */
            produceQuestion: function (autoGenerateAnswerName = true) {
                this.generateQuestionId--;
                const newAnswerName = autoGenerateAnswerName ? this.generateNewVariableName() : '';
                return {
                    id: this.generateQuestionId,
                    title: '',
                    fileId: this.fileId,
                    answerName: newAnswerName,
                    defaultAnswer: null,
                    answerType: VARIABLE_TYPE_OPTIONS.TEXT.value,
                    showControlAnsId: null,
                    showControlAnsVal: null,
                    showControlAction: null,
                    showControlRelationshipType:SHOW_CONTROL_RELATIONSHIP_OPTIONS.VALUE_EQ.value,
                    parentId:null,
                    options: [],
                    groupId: this.getDefaultGroupId(),
                    sortIndex: (this.getMaxSortIndex() + 1),
                    extraUIInfo: this.produceQuestionExtraUIInfo()
                };
            },
            getDefaultGroupId:function(){
                return this.groups && this.groups.length > 0 ? this.groups[0].id : null;
            },
            /**
             * 生成新的变量名数字
             */
            generateNewVariableName: function () {
                do {
                    this.generateVariableNum++
                }while (this.answerNameSet.has('variable' + this.generateVariableNum))
                return 'variable' + this.generateVariableNum;
            },
            /**
             * 获取所有问题中最大的sortIndex
             */
            getMaxSortIndex: function () {
                if (this.items.length === 0) {
                    return 0;
                }
                if (this.items[this.items.length - 1].sortIndex == null) {
                    return this.items.length
                }
                return this.items[this.items.length - 1].sortIndex;
            },
            /**
             * 初始化一个uiInfo
             * @param showControlAns 决定该问题显隐的问题对象，可选，为空时填入一个默认对象
             */
            produceQuestionExtraUIInfo: function (showControlAns) {
                return {
                    activeTab: EDIT_MODEL.BASIC,
                    showControlAns: showControlAns ? showControlAns : this.getDefaultControlAns(),
                    twinkle: false,
                    typeSelectDisable:false,
                    titleChange: false,
                    highSettingExpand:[],
                    relatedSubQuestionPopoverVisible:false,
                    defaultShow: true,
                    refreshShowControlAnsVal:false
                }
            },
            /**
             * 对于上一次进来，可能问题本身已经发生了变化，所以需要对content内容进行重新修正。
             */
            // /**
            //  * 对于单选和多选，当没有选项时的默认值在有了选项之后就不适用了，应该切换为有选项之后的默认值
            //  */
            // fixDefaultValueIfNeed: function (question,answer) {
            //     if (question.answerType === VARIABLE_TYPE_OPTIONS.SINGLE_OPTION.value && answer.content === '') {
            //         if (question.options && question.options.length > 0) {
            //             answer.content = question.options[0].name;
            //         }
            //     } else if (question.answerType === VARIABLE_TYPE_OPTIONS.MULTIPLE_OPTION.value && answer.content.length === 0) {
            //         if (question.options && question.options.length > 0) {
            //             answer.content = [question.options[0].name];
            //         }
            //     }
            // },
            saveQuestion: function (question) {
                let param = Object.assign({}, question);
                questionObjProp2JSON(param)
                //如果是新建的question，则将它id置空，待服务器生成id
                if (param.id < 0) {
                    param.id = null;
                }
                return saveTemplateQuestion(param);
            },
            /**
             * 是否为字符串
             * @param o 测试的变量
             * @return {boolean}
             */
            isStr: function (o) {
                return typeof o === 'string'
            },
            findItemById: function (questionId) {
                for (let i = 0; i < this.items.length; i++) {
                    if (String(questionId) === String(this.items[i].id)) {
                        return this.items[i];
                    }
                }
                return null;
            },
            /**
             * 根据parentId获取子问题
             */
            getChildren:function(parentId){
                const result = [];
                for (let i = 0; i < this.items.length; i++) {
                    if (String(parentId) === String(this.items[i].parentId)) {
                        result.push(this.items[i]);
                    }
                }
                return result;
            },
            findItemByUUID: function (questionUUID) {
                for (let i = 0; i < this.items.length; i++) {
                    if (String(questionUUID) === String(this.items[i].uuid)) {
                        return this.items[i];
                    }
                }
                return null;
            },
            findAnswerSheetByUUID: function (questionUUID) {
                for (let i = 0; i < this.answerSheet.length; i++) {
                    if (String(questionUUID) === String(this.answerSheet[i].question.uuid)) {
                        return this.answerSheet[i];
                    }
                }
                return null;
            },
            getDefaultControlAns: function () {
                return {
                    title: '',
                    answerName: '',
                    answerType: VARIABLE_TYPE_OPTIONS.TEXT.value,
                    options: []
                }
            },
            typeChanged: function (item) {
            },
            /**
             * 决定当前问题显隐的问题已更换
             * @param item 当前问题
             */
            controlAnsChanged: function (item) {
                item.showControlAnsVal = null;
                if (!item.showControlAnsId) {
                    item.extraUIInfo.showControlAns = this.getDefaultControlAns();
                    item.showControlAnsVal = "";
                    item.showControlAction = null;
                    return
                }
                if (item.showControlAnsId && item.showControlAction === null) {
                    item.showControlAction = SHOW_CONTROL_OPTIONS.SHOW.value;
                }
                let question = this.findItemById(item.showControlAnsId);
                if (question) {
                    item.extraUIInfo.showControlAns = question;
                    item.showControlAnsVal = getQuestionDefaultAnswer(question,true);
                }
                // this.reCalculateQuestionAnswerTypeDisabled();
            },
            // /**
            //  * 重新计算每一个问题是否能够改变答案类型
            //  */
            // reCalculateQuestionAnswerTypeDisabled:function(){
            //     console.log('reCalculateQuestionAnswerTypeDisabled**********')
            //     let size = this.items.size;
            //     let associatedQuestionUUIDs = this.getAssociationQuestionFunc()
            //     for (let i = 0; i < size; i++) {
            //         let item = this.items[i];
            //         // 如果文中有关联文本，则disable掉该问题的类型选择
            //         if (associatedQuestionUUIDs.indexOf(item.uuid) > -1) {
            //             item.extraUIInfo.typeSelectDisable = true;
            //             continue;
            //         }
            //         // 如果有其它问题依赖该问题，则也需要disable掉该问题的类型选择
            //         let relatedItems = this.getRelatedQuestionFunc(item.id);
            //         if(relatedItems.length>0){
            //             item.extraUIInfo.typeSelectDisable = true;
            //             continue;
            //         }
            //         item.extraUIInfo.typeSelectDisable = false;
            //     }
            // },
            /**
             * 填写的答案已经改变
             */
            answerChanged: function (answerSheet) {
                let visibleDependencyAnswerSheet = getVisibleDependencyAnswerSheet(answerSheet.answer.question, this.groupAnswerSheets);
                let involvedQuestionVisible = [];
                for (let i = 0; i < visibleDependencyAnswerSheet.length; i++) {
                    let questionVisible = answerSheetShouldShowQuestion(visibleDependencyAnswerSheet[i], this.answerSheetMap);
                    involvedQuestionVisible.push({uuid:visibleDependencyAnswerSheet[i].questionUUID,visible:questionVisible})
                }
                answerSheet.involvedQuestionVisible = involvedQuestionVisible;
                this.$emit('answer-changed', answerSheet);
                console.log('answerSheet:*********',answerSheet)
                if (!answerSheet.answer.question.parentId && answerSheet.answer.question.answerType === VARIABLE_TYPE_OPTIONS.ORGANIZATION.value) {
                    // 回答的组织名称
                    const questionId = answerSheet.answer.question.id;
                    // 查询爱企查，回答相关子问题
                    // const answerContent = answerSheet.answer.answer.content;
                    // this.requestAiQiChaAndAnswerSubQuestions(answerContent, questionId);
                    this.requestAiQiChaAndAnswerSubQuestions(answerSheet, questionId);
                }
            },

            // answer-all-question
            requestAiQiChaAndAnswerSubQuestions: function (answerSheet, questionId) {
                const answerContent = answerSheet.answer.answer.content;
                if (!answerContent || answerContent.length === 0) {
                    return;
                }
                if (this.orq[questionId] && this.orq[questionId]['requesting'] === true) {
                    this.orq[questionId]['waitingRequest'] = true;
                    this.orq[questionId]['recentAnswerSheet'] = answerSheet;
                    return;
                } else {
                    this.orq[questionId] = {
                        'waitingRequest': false,
                        'recentAnswerSheet': answerSheet
                    }
                }

                this.orq[questionId]['requesting'] === true;
                const timestamp = new Date().getTime();
                this.orq[questionId]['requesttime'] = timestamp;
                getAiQiChaCompanyData(answerContent, timestamp).then(res => {
                    console.log('getAiQiChaCompanyData  res === ', res);
                    if (res.data.timestamp != this.orq[questionId]['requesttime']) {
                        console.log('时间不对！！！！！！requesttime === ' + this.orq[questionId]['requesttime']);
                        return;
                    }

                    const currentAnswerSheet = this.findAnswerSheetByUUID(this.orq[questionId]['recentAnswerSheet']['answer']['question']['uuid'])
                    const currentAnswerName = this.orq[questionId]['recentAnswerSheet']['answer']['question']['answerName'];
                    if (res.data.data[answerContent] != null) {
                        currentAnswerSheet['finded'] = true;
                    } else {
                        currentAnswerSheet['finded'] = false;
                    }
                    for (let i = 0; i < this.answerSheet.length; i++) {
                        const item = this.answerSheet[i];
                        if (item.question['parentId'] === questionId) {
                            if (res.data.data[answerContent] != null) {
                                const detail = res.data.data[answerContent]['detail'];
                                const itemAnswerName = item.question.answerName;
                                const itemKey = itemAnswerName.substring(currentAnswerName.length + 1);
                                const companyInfoKey = COMPANY_BASIC_INFO_MAPPING[itemKey];
                                if (detail[companyInfoKey]) {
                                    item.answer.content = detail[companyInfoKey];
                                    item.question.extraUIInfo.defaultShow = false;
                                }else{
                                    item.answer.content = '请填写该组织的' + itemKey;
                                    item.question.extraUIInfo.defaultShow = true;
                                }
                            } else {
                                item.answer.content = '暂未找到该组织';
                                item.question.extraUIInfo.defaultShow = true;
                            }
                            if (item.answer.id) {
                                //如果这是一个已经保存到数据库的答案,刚开始进来只需要通知一下就可以了
                                this.$emit('answer-changed', { "answer": item, "scrollToRect": false});
                            } else {
                                this.$emit('answer-changed', { "answer": item, "scrollToRect": false});
                                this.saveChangedAnswer(item, i);
                            }
                        }
                    }
                    this.orq[questionId]['requesting'] === false;
                    if (this.orq[questionId]['waitingRequest'] === true) {
                        this.requestAiQiChaAndAnswerSubQuestions(this.orq[questionId]['recentAnswerSheet'], questionId)
                    }
                })
            },
            /**
             * 某一个问题（单选、多选）的选项名发生了变化，需要通知其他依赖于它的问题
             */
            questionOptionNameUpdated:function({questionId,oldName,newName}){
                const _this = this;
                console.log('questionOptionNameUpdated',questionId,oldName,newName)
                let changedQuestion = this.findItemById(questionId);
                if (changedQuestion === null) {
                    return;
                }
                this.$emit('question-option-changed', {'questionId': changedQuestion.uuid, 'oldName': oldName, 'newName': newName});
                let relatedQuestions = this.getRelatedQuestionFunc(questionId);
                if (!relatedQuestions || relatedQuestions.length === 0) {
                    return
                }


                const changedQuestionMultiple = multiple(changedQuestion);
                let updateQuestions = relatedQuestions.filter(question=>question.showControlAnsVal&&question.showControlAnsVal.length>0)
                updateQuestions.forEach(
                    question=>{
                        if (changedQuestionMultiple) {
                            // 多选问题改变其中一个选项
                            let index = question.showControlAnsVal.indexOf(oldName)
                            if (index >= 0) {
                                question.showControlAnsVal[index] = newName
                            }
                        }else{
                            // 单选问题直接改变整个值。
                            if(String(question.showControlAnsVal) === String(oldName)){
                                question.showControlAnsVal = newName;
                                // 由于这里进行forceUpdate也没办法刷新它的ui，所以选择了强制修改dom元素
                            }
                        }
                        // 由于el-selection并没有根据新的数据更新ui，我们不得不先把它杀掉
                        question.extraUIInfo.refreshShowControlAnsVal = true;
                    }
                )
                this.$nextTick(()=>{
                    // 然后再重新生成
                    updateQuestions.forEach(question=>question.extraUIInfo.refreshShowControlAnsVal = false)
                })
            },
            questionAnswerInfoChanged: function (question, changeAnswerName, oldAnswerName) {
                this.$emit('question-answer-info-changed', question)
                if (question.answerType === VARIABLE_TYPE_OPTIONS.ORGANIZATION.value && !question.parentId && changeAnswerName === true) {
                    // 如果是修改组织名称变量名，需要同步修改子问题的变量名
                    for (const q of this.items) {
                        if (q.parentId === question.id) {
                            const subQuestionName = q.answerName;
                            const suffix = subQuestionName.substring(oldAnswerName.length);
                            q.answerName = question.answerName + suffix;
                            this.$emit('question-answer-info-changed', q);
                        }
                    }
                }
            },
            highSettingExpandChanged: function ({id, highSettingExpand}) {
                if (highSettingExpand && highSettingExpand.length === 0) {
                    return;
                }
                this.items.forEach(item => {
                    // 将其它展开的高级设置收起。
                    if (item.id !== id) {
                        item.extraUIInfo.highSettingExpand = [];
                    }
                });
            },
            openAddOrLinkQuestionDialog: function (param, addOrLinkQuestionCallback) {
                // console.log('openAddOrLinkQuestionDialog  param === ', param)
                this.addOrLinkParam = param;
                this.addOrLinkQuestionCallback = addOrLinkQuestionCallback;
                this.relatedParentQuestionId = null;
                this.standAnswer['text'] = '';
                this.standAnswer['bool'] = null;
                this.standAnswer['single'] = '';
                this.standAnswer['multi'] = [];
                this.standAnswer['showHide'] = 'show';
                if (this.addOrLinkQuestionDialogActiveName === 'add' || this.items.length === 0) {
                    this.setDefaultQuestion(this.produceQuestion(false));
                    // 如果是新建填空变量，判断选中文本是否需要智能设置变量名
                    if (this.addOrLinkParam.renderType === 'text') {
                        const selectText = this.addOrLinkParam.text.trim();
                        const leftKh = '【[（({';
                        const rightKh = '】]）)}';
                        if (selectText.length > 1 && leftKh.indexOf(selectText[0]) >= 0 && leftKh.indexOf(selectText[0]) === rightKh.indexOf(selectText[selectText.length - 1])) {
                            this.emptyQuestion.answerName = selectText.substring(1, selectText.length - 1);
                            this.emptyQuestion.title = `请问${this.emptyQuestion.answerName}是什么？`
                        }
                    }
                } else {
                    this.setDefaultQuestion(null);
                }
                this.addOrLinkQuestionDialogVisible = true;
                this.$nextTick(() => {
                    this.addOrLinkQuestionDialogOpened();
                });
            },

            setDefaultQuestion: function (defaultQuestion) {
                this.addOrLinkQuestionDialogTitle = this.addOrLinkParam.renderType === 'text' ? '填空变量' : '控制变量';
                this.emptyQuestion = defaultQuestion;
                if (this.emptyQuestion) {
                    this.emptyQuestion.answerType = this.addOrLinkParam.renderType === 'text' ? VARIABLE_TYPE_OPTIONS.TEXT.value : VARIABLE_TYPE_OPTIONS.BOOL.value
                }
            },
            addOrLinkQuestionDialogOpened: function () {
                if (this.addOrLinkQuestionDialogActiveName === 'add') {
                    const answerNameInput = document.querySelector('.addOrLinkQuestionDialog .answerNameInput input');
                    answerNameInput && answerNameInput.focus();
                } else {
                    document.querySelectorAll('.addOrLinkQuestionRadioGroup').forEach((ele) => {
                        ele.scrollTop = 0;
                    });
                }
            },
            addOrLinkQuestionTabClick: function () {
                if (this.addOrLinkQuestionDialogActiveName === 'relate') {
                    // 切换到关联
                    if (!this.relatedParentQuestionId || this.relatedParentQuestionId === '') {
                        this.emptyQuestion = null;
                    } else {
                        this.relatedQuestionChanged(this.relatedParentQuestionId);
                    }
                } else {
                    this.setDefaultQuestion(this.produceQuestion(false));
                }
            },
            questionChanged: function (newQuestion) {
            },
            getPureOrganizationName:function(name){
                const splitIndex = name.lastIndexOf('.');
                if (splitIndex < 0) {
                    return name;
                }
                return name.substring(0, splitIndex);
            },
            getParentQuestionDefaultName: function (name, answerType) {
                if (VARIABLE_TYPE_OPTIONS.ORGANIZATION.value === answerType) {
                    return '组织名称';
                }
                return name;
            },
            /**
             * 获取父问题的隐藏变量名（用于占位，不允许其他问题取同样的变量名）
             */
            getParentQuestionHiddenVariableName: function (name, answerType) {
                if (VARIABLE_TYPE_OPTIONS.ORGANIZATION.value === answerType) {
                    return name + '.组织名称';
                }
                return name;
            },
            getOrganizationPropertyName:function(name){
                const splitIndex = name.lastIndexOf('.');
                if (splitIndex < 0) {
                    return name;
                }
                return name.substring(splitIndex + 1, name.length);
            },
            relatedQuestionChanged: function (relatedParentQuestionId,relatedSubQuestionId) {
                this.relatedParentQuestionId = relatedParentQuestionId;
                this.relatedSubQuestionId = relatedSubQuestionId;
                if (relatedSubQuestionId) {
                    // 如果有子问题id，则子问题优先，relatedParentQuestionId用来让户问题单选框保持选中效果。
                    this.emptyQuestion = this.findItemById(relatedSubQuestionId);
                }else {
                    this.emptyQuestion = this.findItemById(relatedParentQuestionId);
                }
                const parentQuestion = this.findItemById(relatedParentQuestionId);
                if (parentQuestion) {
                    parentQuestion.extraUIInfo.relatedSubQuestionPopoverVisible = false;
                }
            },
            selectDefaultOrganizationQuestion:function(parentId){
                if (this.relatedParentQuestionId !== parentId || !this.relatedSubQuestionId) {
                    //如果之前选中了其它问题，或者选中了当前是当前问题，但没有选当前问题的子问题，则设置默认子问题
                    this.relatedQuestionChanged(parentId);
                }
                this.closeOtherOrganizationPopover(parentId);
            },
            closeOtherOrganizationPopover: function (id) {
                this.items.forEach(item => {
                    if (item.id !== id) {
                        item.extraUIInfo.relatedSubQuestionPopoverVisible = false;
                    }
                })
            },
            /**
             * 是否关联了户问题
             * @param parentId
             * @return {boolean|boolean}
             */
            isRelatedParentQuestion:function(parentId){
                return this.relatedParentQuestionId === parentId && !this.relatedSubQuestionId;
            },
            /**
             * 是否关联了子问题
             * @param parentId
             * @param childId
             * @return {boolean|boolean}
             */
            isRelatedSubQuestion: function (parentId, childId) {
                return this.relatedParentQuestionId === parentId && this.relatedSubQuestionId === childId;
            },
            addOrLinkQuestionCancle: function () {
                this.addOrLinkQuestionDialogVisible = false;
            },
            addOrLinkQuestionSubmit: function () {
                if (this.addOrLinkQuestionSubmitFreeze === true) {
                  return;
                }
                if (!this.emptyQuestion.answerName) {
                    metaMessage['error']('变量名不能为空！');
                    return;
                }
                // 如果是添加，则需要检查该变量名是否已经存在了。
                if (this.addOrLinkQuestionDialogActiveName === ADD_RELATED_MODE.ADD && this.answerNameExistFunc(this.emptyQuestion.id, this.emptyQuestion.answerName)) {
                    metaMessage['error']('变量名已存在！');
                    return;
                }

                if ((this.emptyQuestion.answerType === VARIABLE_TYPE_OPTIONS.SINGLE_OPTION.value || this.emptyQuestion.answerType === VARIABLE_TYPE_OPTIONS.MULTIPLE_OPTION.value)
                && this.emptyQuestion.options.length === 0) {
                    metaMessage['error']('请输入候选项！');
                    return;
                }

                let standAnswer = null;
                if (this.addOrLinkParam.renderType === 'bool') {
                    if (this.emptyQuestion.answerType === VARIABLE_TYPE_OPTIONS.TEXT.value || this.emptyQuestion.answerType === VARIABLE_TYPE_OPTIONS.ORGANIZATION.value) {
                        standAnswer = this.standAnswer.text;
                        if (this.standAnswer.text.length === 0) {
                            metaMessage['error']('请输入变量显示的条件');
                            return;
                        }
                    }
                    if (this.emptyQuestion.answerType === VARIABLE_TYPE_OPTIONS.BOOL.value) {
                        standAnswer = this.standAnswer.bool;
                        if (!this.standAnswer.bool) {
                            metaMessage['error']('请设置变量显示的条件');
                            return;
                        }
                    }
                    if (this.emptyQuestion.answerType === VARIABLE_TYPE_OPTIONS.SINGLE_OPTION.value) {
                        standAnswer = this.standAnswer.single;
                        if (!this.standAnswer.single || this.standAnswer.single.length === 0) {
                            metaMessage['error']('请选择变量显示的条件');
                            return;
                        }
                    }
                    if (this.emptyQuestion.answerType === VARIABLE_TYPE_OPTIONS.MULTIPLE_OPTION.value) {
                        standAnswer = this.standAnswer.multi;
                        if (this.standAnswer.multi.length === 0) {
                            metaMessage['error']( '请选择变量显示的条件');
                            return;
                        }
                    }
                }
                this.addOrLinkQuestionSubmitFreeze = true;
                // 此时添加的问题肯定是关联了区域的
                this.emptyQuestion['related'] = 1;
                this.saveQuestion(this.emptyQuestion).then(res => {
                    if (res.data.code === 0) {
                        this.emptyQuestion.id = res.data.data.id;
                        this.emptyQuestion.uuid = res.data.data.uuid;
                        this.addQuestion = false;
                        if (this.addOrLinkQuestionDialogActiveName === 'add') {
                            // 添加
                            this.pushQuestion(this.emptyQuestion);
                            if (this.emptyQuestion.answerType === VARIABLE_TYPE_OPTIONS.ORGANIZATION.value) {
                                // 需要将子问题也push进来
                                getSubQuestions(this.emptyQuestion.id).then(questionRes => {
                                    if (questionRes.data.code === 0) {
                                        for (const subQuestion of questionRes.data.data) {
                                            subQuestion.extraUIInfo = this.produceQuestionExtraUIInfo();
                                            this.pushQuestion(subQuestion);
                                        }
                                    }
                                });
                            }
                        }
                        this.$nextTick(() => {
                            this.focusQuestionById(this.emptyQuestion.id)
                        })
                        this.addOrLinkQuestionDialogVisible = false;
                        if (this.addOrLinkQuestionCallback instanceof Function) {
                            this.addOrLinkQuestionCallback({code:0, msg:"success", data: this.emptyQuestion, standardAnswer: standAnswer, standAnswerShowHide: this.standAnswer.showHide});
                        }
                        this.addOrLinkQuestionSubmitFreeze = false;
                    } else if (res.data.code === 409) {
                        metaMessage['error']('变量名已存在');
                        this.addOrLinkQuestionSubmitFreeze = false;
                    } else {
                        metaMessage['error']('保存问题失败');
                        this.addOrLinkQuestionSubmitFreeze = false;
                    }
                }).catch(err => {
                    metaMessage['error']('添加/保存问题失败');
                    console.error('添加/保存问题失败  err:', err)
                    this.addOrLinkQuestionSubmitFreeze = false;
                });
            },
            showAlert: function (title, content, confirmCallback) {
                this.$alert(content, title, {
                    confirmButtonText: '确定',
                    callback: action => {
                        if (confirmCallback instanceof Function) {
                            confirmCallback();
                        }
                    }
                });
            },
        }

    }
</script>

<style lang="scss">
    .groupMenuIcon{
        width: 14px;
        margin-right: 10px;
    }
    .groupQuestionCollapse{
        .el-collapse-item__header{
            font-weight: bold;
            background-color: #F6F7FA;
            padding-left: 15px;
        }
        .el-collapse-item__wrap{
            padding-left: 15px;
            border: none !important;
        }
    }
    .organizationMenuItem{
        font-size: 12px;
        padding:0 10px 0 30px;
        line-height: 20px;
        /*display: flex;*/
        /*justify-content: space-between;*/
        cursor: pointer;
    }
    .organizationMenuItem i {
        margin-right: 8px;
        margin-left: -20px;
        color:#409EFF;
    }
    .organizationMenuItem:hover{
        background-color: #f5f7fa;
    }

    .activeQuestion .is-always-shadow{
        box-shadow: 0 2px 12px 0 rgb(128 174 222);
    }
    .leftMenu {
        position: fixed;
        // width: 300px;
        height: 100%;
        // overflow: auto;
      background: #fff;
    }

    .leftScroller{
        padding-right: 8px;
    }
    .leftScroller::-webkit-scrollbar {
        /*display: none;*/
        width: 5px;
        height: 5px;
        display: block;

    }
    .leftScroller::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        background-clip: padding-box;
        min-height: 28px;
    }

    .dialog-standardAnswer {
        text-align: left;
        margin-bottom: 10px;
    }

    .dialog-standardAnswer div {
        line-height: 30px;
    }

    .question-selection-div{
        text-align: left;
        padding-bottom: 20px;
    }

    .addOrLinkQuestionRadioGroup {
        /*max-height: 150px;*/
        overflow-y: auto;
        text-align: left;
    }
    .addOrLinkQuestionDialog{
        padding:10px;
    }

    .addOrLinkQuestionDialog .el-tabs__content {
        overflow-y: auto;
    }

    .addOrLinkQuestionDialog .el-tabs__content::-webkit-scrollbar {
        width: 9px;
        height: 9px;
        display: block;
    }

    .addOrLinkQuestionDialog .el-tabs__content::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        background-clip: padding-box;
        min-height: 28px;
    }

    .addOrLinkQuestionDialog .el-tabs__content::-webkit-scrollbar-thumb:hover {
        background-color: #bbb;
    }

    .addOrLinkQuestionDialog .el-tabs__content::-webkit-scrollbar-track-piece {
        background-color: #f8f8f8;
    }

    .el-card__body .el-tabs__content::-webkit-scrollbar {
        display: none;
    }

    .addOrLinkQuestionRadioGroup::-webkit-scrollbar {
        width: 9px;
        height: 9px;
        display: block;
    }
    .addOrLinkQuestionRadioGroup::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        background-clip: padding-box;
        min-height: 28px;
    }
    .addOrLinkQuestionRadioGroup::-webkit-scrollbar-thumb:hover {
        background-color: #bbb;
    }
    .addOrLinkQuestionRadioGroup::-webkit-scrollbar-track-piece {
        background-color: #f8f8f8;
    }

    .addOrLinkQuestionRadioGroup label {
        width: 150px;
        margin-top: 5px;
        margin-left: 10px !important;
        margin-right: 10px !important;
        padding: 8px 15px 0 10px !important;
        border-radius: 3px !important;
        height: 32px !important;
    }

    .addOrLinkQuestionRadioGroup .el-radio__input,.el-checkbox__input {

    }

    .addOrLinkQuestionRadioGroup .el-radio__label,.el-checkbox__label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
    }

    .answerBox .el-card {
        border: none !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
    }

    .addOrLinkQuestionDialogOverlay {
        position: fixed;
        right: 0;
        left: 0;
        z-index: 2000;
        background-color: rgba(0,0,0,.5);
        top: 0;
        bottom: 0;
        height: 100%;
    }

    .addOrLinkQuestionDialog {
        width: 400px;
        text-align: center;
        position: relative;
        margin: 0 auto 50px;
        background: #FFF;
        border-radius: 2px;
        -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
        box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin-top: 100px;
    }

    .addOrLinkQuestionDialog-header {
        padding: 20px 20px 10px;
    }
    .addOrLinkQuestionDialog #tab-add,#tab-relate{
        padding-left: 20px;
        padding-right:20px;
    }
    #answer-tip{
      width: 70%;margin-left: 15%;margin-top:1px;
      overflow:visible;
    }
    .template-tip-wrap{
      width: 500px;
    }

</style>

<template>
    <div>
        <el-dropdown trigger="click" @command="typeChange" @visible-change="visibleChanged" :disabled="disabled">
            <div class="el-select el-select--mini"  @pointerdown.stop>
                <div class="select-trigger">
                    <div class="el-input el-input--mini el-input--suffix">
<!--                        <i class="icon iconfont" :class="selectItem.icon"></i>-->
<!--                        <input class="el-input__inner" type="text" readonly placeholder="变量类型" :value="selectItem.name"/>-->
                        <div class="el-input__inner" style="min-width: 116px;font-size: 12px;vertical-align: middle;" type="text" readonly placeholder="变量类型">
                            <i class="icon iconfont small-font" style="margin-right: 5px;" :class="selectItem.icon"></i>{{selectItem.name}}
                        </div>
                        <span class="el-input__suffix">
                            <span class="el-input__suffix-inner">
                                <i class="el-select__caret el-input__icon el-icon-arrow-up"></i>
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <template #dropdown>
<!--                :disabled="(st === 'dialog' && rt === 'text' && option.value === VARIABLE_TYPE_OPTIONS.BOOL.value) || element.extraUIInfo.typeSelectDisable"-->
                <el-dropdown-menu>
                    <el-dropdown-item v-for="option in variableTypes" :key="option.value" :disabled="optionDisabled(option)" :command="option.value">
                        <div class="small-font"><i class="icon iconfont small-font" :class="option.icon"></i>{{option.name}}</div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>

    </div>
</template>

<script>
    import {VARIABLE_TYPE_OPTIONS} from "../constant/question";

    export default {
        name: "AnswerTypeSelector",
        props: {
            'modelValue':{
                type: Number,
                default:function () {
                    return VARIABLE_TYPE_OPTIONS.TEXT.value
                }
            },
            "disabled": {
                type:Boolean,
                default:function () {
                    return false;
                }
            },
            disableOptions: {
                type:Array,
                default: function () {
                    return [];
                }
            },
            beforeChange:{type:Function}
        },
        data: function () {
            return {
                VARIABLE_TYPE_OPTIONS: VARIABLE_TYPE_OPTIONS,
                variableTypes: this.getObjectValues(VARIABLE_TYPE_OPTIONS),
            };
        },
        computed: {
            variableTypeMap: function () {
                const map = new Map();
                this.variableTypes.forEach(type => map.set(type.value, type));
                return map;
            },
            selectItem :function () {
                let option = this.variableTypeMap.get(this.modelValue);
                if (!option) {
                    return this.getDefaultOption();
                }
                return option;
            }
        },
        watch: {},
        mounted() {

        },
        methods: {
            typeChange:function(command){
                const oldValue = this.modelValue;
                if (this.beforeChange) {
                    this.beforeChange(command, oldValue).then(()=>{
                        this.$emit('update:modelValue', command);
                        this.$emit('change', command,oldValue);
                    });
                }else{
                    this.$emit('update:modelValue', command);
                    this.$emit('change', command,oldValue);
                }
            },
            visibleChanged:function(visible){
                this.$emit('visibleChange', visible);
            },
            getDefaultOption:function(){
                return VARIABLE_TYPE_OPTIONS.TEXT;
            },
            optionDisabled:function(option){
                return this.disabled || this.disableOptions.indexOf(optionVal => optionVal === option.value) >= 0;
            },
            getObjectValues: function (obj) {
                let values = [];
                for (let key in obj) {
                    values.push(obj[key]);
                }
                return values;
            },
        }
    }
</script>

<style scoped>
    .small-font{
        font-size: 12px;
    }
</style>

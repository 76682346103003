<template>
    <div v-show="currentViewMode===VIEW_MODE.ELEMENT_TIP" @mousedown.stop.prevent>
        <div  class="overlay" :style="{width:overlay.overlayTopDiv.width+'px',height:overlay.overlayTopDiv.height+'px',top:overlay.overlayTopDiv.top+'px',left:overlay.overlayTopDiv.left+'px'}"></div>
        <div  class="overlay" :style="{width:overlay.overlayLeftDiv.width+'px',height:overlay.overlayLeftDiv.height+'px',top:overlay.overlayLeftDiv.top+'px',left:overlay.overlayLeftDiv.left+'px'}"></div>
        <div  class="overlay" :style="{width:overlay.overlayRightDiv.width+'px',height:overlay.overlayRightDiv.height+'px',top:overlay.overlayRightDiv.top+'px',left:overlay.overlayRightDiv.left+'px'}"></div>
        <div  class="overlay" :style="{width:overlay.overlayBottomDiv.width+'px',height:overlay.overlayBottomDiv.height+'px',top:overlay.overlayBottomDiv.top+'px',left:overlay.overlayBottomDiv.left+'px'}">
        </div>
        <div  class="guideTipLayer" :style="tipLayerStyle" :class="tipLayerClass" v-if="steps[currentStepIndex]&&steps[currentStepIndex].config.tip.html">
            <div v-html="steps[currentStepIndex].config.tip.html" :style="steps[currentStepIndex].config.tip.css"></div>
            <div class="tipLayerArrow" >
            </div>
            <div style="height: 38px;width: 100%" v-if="steps[currentStepIndex].config.showNextButton"></div>
            <div class="goNextContainer" v-if="steps[currentStepIndex].config.showNextButton"> <el-button @click="goNextStep" size="mini">{{currentStepIndex===steps.length-1?'完成':'下一步'}}</el-button></div>
        </div>
    </div>
    <div v-if="currentViewMode===VIEW_MODE.TIP" class="overlay pureTipContainer" @mousedown.stop.prevent>
        <div class="pureTip">
            <div v-if="steps[currentStepIndex].config.tip.html" v-html="steps[currentStepIndex].config.tip.html" :style="steps[currentStepIndex].config.tip.css"></div>
            <div class="goNextContainer" v-if="steps[currentStepIndex].config.showNextButton"> <el-button @click="goNextStep" size="mini">{{currentStepIndex===steps.length-1?'完成':'下一步'}}</el-button></div>
        </div>
    </div>

</template>
<style lang="scss">
    .pureTipContainer{
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pureTip{
        text-indent: 16px;
        position: relative;
        background-color: #fff;
        min-width: 250px;
        max-width: 300px;
        min-height: 100px;
        border-radius: 5px;
        box-shadow: 0 3px 30px rgb(33 33 33 / 30%);
        transition: opacity .1s ease-out;
        line-height: 1.5;
        text-align: left;
        padding:10px;
    }

    .overlay{
        position: fixed;
        z-index: 100000000;
        background-color: rgb(33 33 33 / 50%);
    }
    .guideTipLayer{
        text-indent: 16px;
        position: fixed;
        z-index: 100000001;
        background-color: #fff;
        min-width: 250px;
        max-width: 300px;
        min-height: 60px;
        border-radius: 5px;
        line-height: 1.5;
        text-align: left;
        box-shadow: 0 3px 30px rgb(33 33 33 / 30%);
        transition: opacity .1s ease-out;
        padding:10px;
    }
    .tipLayerArrow{
        position: absolute;
        border: 5px solid transparent;
    }
    .guideTipLayer.left .tipLayerArrow{
        right: -10px;
        top: 10px;
        border-left-color: #fff;
    }
    .guideTipLayer.right .tipLayerArrow{
        left: -10px;
        top: 10px;
        border-right-color: #fff;
    }
    .goNextContainer{
        position: absolute;
        bottom: 10px;
        width: 100%;
        text-align: center;
    }
</style>
<script>
    // 步骤示例
    // const exampleStep = {
    //     "type": "click",
    //     "config": {
    //         "selector": ".header .el-button-group:first-child",
    //         "tip": {
    //             "html": "",
    //             "css": ""
    //         },
    //         "zIndex": "",
    //         "sleep":0,
    //         "showNextButton:false,
    //     }
    // }

    const STEP_TYPE = {
        CLICK:'click',
        NOTICE:'notice',
        TEXT_RANGE:'text_range',
        INPUT:'input',
        AUTO_GO_NEXT:'auto_go_next',//自动走向下一步，一般与sleep结合起来使用，做短暂的提示作用。
    };
    const VIEW_MODE = {
        NONE:'none',// 不显示
        ELEMENT_TIP:'element_tip',//显示有目标区域的tip
        TIP:'tip'//单纯显示tip
    }
    export default {
        name: "noviceGuide",
        props: [],
        data:function(){
            return {
                currentViewMode:VIEW_MODE.NONE,
                VIEW_MODE:VIEW_MODE,
                view_mode:0,
                currentStepIndex: 0,
                steps: [],
                overlay:{
                    overlayTopDiv: {top: 0, width: 0, left: 0, height: 0},
                    overlayLeftDiv: {top: 0, width: 0, left: 0, height: 0},
                    overlayRightDiv: {top: 0, width: 0, left: 0, height: 0},
                    overlayBottomDiv: {top: 0, width: 0, left: 0, height: 0},
                },
                tipLayerStyle: {},
                tipLayerClass:[]
            };
        },
        methods: {
            init(steps){
                console.log('guide init******')
                this.steps = steps;
                this.beforeGuide();
                return this;
            },
            beforeGuide(){
                document.addEventListener('keydown',this.disableRollback,true)
            },
            afterGuide(){
                document.removeEventListener('keydown',this.disableRollback,true)
            },
            disableRollback(e){
                console.log('linsten keydown ')
                let keyCode=e.keyCode || e.which
                // 屏蔽 ctrl+z
                if(e.ctrlKey&&keyCode === 90){
                    e.preventDefault();
                    e.stopPropagation();
                }
            },
            start(index) {
                console.log('noviceGuide')
                this.goStep(index?index:0);
                return this;
            },
            goPreStep(){
                this.goStep(this.currentStepIndex - 1);
                return this;
            },
            goNextStep(){
                let currentStep = this.steps[this.currentStepIndex];
                // 如果当前操作需要等待，则在等待后在进行下一步
                if (currentStep && currentStep.config.sleep) {
                    setTimeout(()=>{
                        this.goStep(this.currentStepIndex + 1);
                    },currentStep.config.sleep)
                }else {
                    this.goStep(this.currentStepIndex + 1);
                }
                return this;
            },
            switchViewMode(mode){
                this.currentViewMode = mode;
                if (mode === VIEW_MODE.NONE) {
                    this.afterGuide();
                }
            },
            goStep(index){
                if (index > 0) {
                    this.$emit('guide-started')
                }
                if (index >= this.steps.length || index < 0) {
                    this.switchViewMode(VIEW_MODE.NONE);
                    return;
                }
                this.currentStepIndex = index;
                this.processStep(this.steps[index]);
                return this;
            },
            processStep(step, currentRetryCount = 0) {
                try{
                    if (currentRetryCount > 6) {
                        // 如果重试了6次，也就是3秒钟过后，仍然找不到element，则报错
                        throw new Error('无法找到目标元素,' + step.config.selector);
                    }
                    if (currentRetryCount > 0) {
                        console.log('wait****,count'+currentRetryCount);
                    }
                    let element = document.querySelector(step.config.selector);
                    if (step.config.selector && element === null) {
                        // 如果这个元素还没有显示出来，就等500毫秒再重试
                        setTimeout(()=>this.processStep(step,++currentRetryCount), 500);
                        return;
                    }
                    this.calculateGuidePosition(step,element)

                    switch (step.type) {
                        case STEP_TYPE.CLICK:
                            this.bindStepClickEvent(step, element);
                            break;
                        case STEP_TYPE.TEXT_RANGE:
                            this.bindStepSelectEvent(step, element)
                            break;
                        case STEP_TYPE.INPUT:
                            this.bindStepInputEvent(step, element);
                            break;
                        case STEP_TYPE.AUTO_GO_NEXT:
                            this.goNextStep();
                            break;
                    }
                }catch (e) {
                    this.$emit('guide-error')
                    this.$message.error('哎呀，引导失败了o(╥﹏╥)o。给您带来的不便请多包涵。')
                    console.error(e)
                    this.switchViewMode(VIEW_MODE.NONE);
                }
            },
            calculateGuidePosition(step,element){
                if (step.type === STEP_TYPE.NOTICE && element === null) {
                    // 如果这一步是提示消息，并且没有目标元素，那么就居中处理
                    this.switchViewMode(VIEW_MODE.TIP);
                }else{
                    let rect = this.calculateRect(step, element);
                    this.overlay = this.calculateOverlayDivByRect(rect);
                    this.tipLayer = this.calculateTipLayer(rect)
                    this.switchViewMode(VIEW_MODE.ELEMENT_TIP);
                }
            },
            calculateRect(step,element){
                let rect = element.getBoundingClientRect();
                if (step.type === STEP_TYPE.NOTICE && !step.config.selector) {
                    // 如果是通知型，并且没有selector，则将其放到中间
                    return null;
                }
                return rect;
            },
            /**
             * 根据一块矩形区域计算overlay的属性。
             * @param rect
             * @return {{overlayBottomDiv: {top: *, left: number, width: number, height: number}, overlayLeftDiv: {top: *, left: number, width: *, height: *}, overlayRightDiv: {top: *, left: *, width: number, height: *}, overlayTopDiv: {top: number, left: number, width: number, height: *}}}
             */
            calculateOverlayDivByRect:function (rect) {
                let availWidth = window.screen.availWidth;
                let availHeight = window.screen.availHeight;
                const overlayTopDiv = {top: 0, left: 0, width: availWidth, height: rect.top};
                const overlayLeftDiv = {top: rect.top, left: 0, width: rect.left, height: rect.height};
                const overlayRightDiv = {top: rect.top, left: rect.left + rect.width, width: availWidth -rect.left - rect.width, height: rect.height};
                const overlayBottomDiv = {top: rect.top + rect.height, left: 0, width: availWidth, height: availHeight - rect.top -rect.height};
                return {
                    overlayTopDiv: overlayTopDiv,
                    overlayLeftDiv: overlayLeftDiv,
                    overlayRightDiv: overlayRightDiv,
                    overlayBottomDiv: overlayBottomDiv,
                }
            },
            calculateTipLayer:function(rect){
                let availWidth = window.screen.availWidth;
                // let availHeight = window.screen.availHeight;
                // let rightWidth = availWidth - (rect.left + rect.width);

                let style = {top: this.p(rect.top)};
                if (this.overlay.overlayRightDiv.width >= this.overlay.overlayLeftDiv.width) {
                    // 如果右边区域比较大，则放到右边
                    style.left = this.p(this.overlay.overlayRightDiv.left + 10);
                    this.tipLayerClass = ['right'];
                }else{
                    // 否则放到左边
                    style.right = this.p(availWidth - this.overlay.overlayLeftDiv.width + 10);
                    this.tipLayerClass = ['left'];
                }
                this.tipLayerStyle = style;

            },
            p:function(num){
                return num + 'px';
            },
            /**
             * 绑定点击事件
             * @param step
             * @param element
             */
            bindStepClickEvent:function (step,element) {
                let self = this;
                let _listener = function (e) {
                    console.log('stepClickListener', e);
                    self.goNextStep();
                    element.removeEventListener('click', _listener);
                };
                element.addEventListener('click',_listener);
            },
            /**
             * 绑定文本选中事件
             * @param step
             * @param element
             */
            bindStepSelectEvent:function (step,element) {
                let self = this;
                let _listener = function (e) {
                    // console.log(e);
                    // console.log(`matchText:${step.config.extra.matchText},selectionStr:${window.getSelection().toString()}`);
                    // 这里之所以延时50毫秒是因为防止有时已经选中了对应文本，但是没有触发mouseup事件，此时单击选取内一个地方，会发现触发mouseup后选取文本依然是之前的文字，从而事件被不正确的触发了。
                    setTimeout(()=>{
                        if(step.config.extra.matchText === window.getSelection().toString()){
                            self.goNextStep();
                            element.removeEventListener('mouseup', _listener);
                        }
                    },50)
                };
                element.addEventListener('mouseup',_listener);
            },
            /**
             * 绑定输入框的
             * @param step
             * @param element
             */
            bindStepInputEvent:function (step,element) {
                let self = this;
                let _listener = function (e) {
                    if(self.checkInputValue(step.config.extra.matchText,element.value,step.config.extra.isAmount)){
                        self.goNextStep();
                        element.removeEventListener('input', _listener);
                    }
                };
                element.addEventListener('input',_listener);
            },
            checkInputValue:function (matchText,val,isAmount) {
                if (!isAmount) {
                    return matchText === val;
                }
                return matchText.replaceAll(/[,，]/g, '') === val.replaceAll(/[,，]/g, '');
            }
        },
    }
</script>

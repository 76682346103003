<template>
    <div style="width: 100%;" ref="container">
        <el-popover trigger="click" :width="popoverWidth"  v-model:visible="visible" popper-class="selectorPopover" @show="popoverShow">
            <template #reference>
                <el-input v-model="text" :placeholder="placeHolderReal" :size="size" readonly  @pointerdown.stop>
                    <template #suffix>
                        <i class="el-input__icon el-icon-arrow-down" @click="visible=!visible"></i>
                    </template>
                </el-input>
            </template>
                <el-input v-model="newGroupName"  :size="size" :placeholder="createItemPlaceholderReal" @keydown.enter="createItem({name:newGroupName})">
                    <template #suffix>
                        <i class="el-input__icon el-icon-plus"  style="cursor: pointer" @click="createItem({name:newGroupName})"></i>
                    </template>
                </el-input>


            <div style="margin-top: 5px;line-height: 30px;max-height: 300px;overflow-y: auto;">

                <template v-for="(group,index) in groups" :key="group.id" >
                    <div class="menuItem" @click="selectedItem(group.id)" v-if="group.id!==editGroup.id">
                        <span>{{group.name}}</span>
                        <div>
                            <i class="el-input__icon el-input__right el-icon-top" @click.stop="moveUpItem(index)"></i>
                            <i class="el-input__icon el-input__right el-icon-bottom" @click.stop="moveDownItem(index)"></i>
                            <i class="el-input__icon el-input__right el-icon-edit" @click.stop="startEditItem(group)"></i>
                            <i class="el-input__icon el-input__right el-icon-delete" @click.stop="deleteItem(group)"></i>
                        </div>
                    </div>
                    <div v-else>
                        <el-input v-model="editGroup.name" @click.stop @mouseover.prevent.stop>
                            <template #suffix>
                                <i class="el-input__icon el-icon-check" style="cursor: pointer" @click.stop="updateItem(editGroup)"></i>
                                <i class="el-input__icon el-input__right el-icon-close" style="cursor: pointer" @click.stop="resetToSelectMode()"></i>
                            </template>
                        </el-input>
                    </div>
                </template>

<!--                <div class="menuItem" @click="selectedItem(group.id)">-->
<!--                    <template v-if="group.id===editGroup.id">-->
<!--                        <el-input v-model="editGroup.name" @click.stop @mouseover.prevent.stop>-->
<!--                            <template #suffix>-->
<!--                                <i class="el-input__icon el-icon-check" @click.stop="updateItem(editGroup)"></i>-->
<!--                            </template>-->
<!--                        </el-input>-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                        <span>{{group.name}}</span>-->
<!--                        <i class="el-input__icon el-input__right el-icon-edit" @click.stop="startEditItem(group)"></i>-->
<!--                    </template>-->

<!--                </div>-->
            </div>


        </el-popover>
    </div>
</template>

<script>
    export default {
        name: "TemplateQuestionGroupSelector",
        props: ['groupId','groups','placeHolder','createItemPlaceholder','size','saveItemFunc','deleteItemFunc','checkSelectFunc','groupOrderExchange'],
        data:function () {
            return {
                text: null,
                visible: false,
                popoverWidth: 230,
                newGroupName:'',
                editGroup: {id:-1},
                placeHolderReal:this.placeholder?this.placeholder:'请选择',
                createItemPlaceholderReal:this.createItemPlaceholder?this.createItemPlaceholder:'在这里新建选项',
            };
        },
        computed:{
            groupMap: function () {
                const result = new Map();
                if (this.groups) {
                    this.groups.forEach(group => result.set(group.id, group.name));
                }
                return result;
            }
        },
        watch:{
            'groups':{
                handler: function (newval, oldval) {
                    this.text = this.groupMap.get(this.groupId)
                },
                deep:true,
            }
        },
        mounted() {
            // 初始化时根据groupId获取设置输入框的名字。
            this.setShowTextById(this.groupId)
        },
        methods:{
            popoverShow:function(){
                if (this.$refs.container) {
                    console.log(this.$refs.container.clientWidth)
                    this.popoverWidth = this.$refs.container.clientWidth ;
                }
            },
            selectedItem:function (id) {
                if (this.checkSelectFunc && !this.checkSelectFunc(id,this.groupId)) {
                    this.visible = false;
                    return
                }
                this.setShowTextById(id);
                this.$emit('update:group-id', id);
                this.visible = false;
            },
            setShowTextById:function (id) {
                this.text = this.groupMap.get(id);
            },
            createItem:function (group) {
                // console.log(this.newGroupName);
                // this.visible = false;
                if (this.saveItemFunc) {
                    this.saveItemFunc(group).then(()=>{
                        this.newGroupName = '';
                    })
                }
            },
            updateItem: function (group) {
                if (this.saveItemFunc) {
                    Promise.resolve(this.saveItemFunc(group)).then(res=>this.resetToSelectMode()).catch(error=>{
                        this.$message.error('修改失败');
                        this.resetToSelectMode();
                    })
                }
            },
            resetToSelectMode:function(){
                this.editGroup = {id: -1};
            },
            startEditItem:function (group) {
                this.editGroup = Object.assign({}, group);
            },
            deleteItem:function (group) {
                if (this.deleteItemFunc) {
                    this.deleteItemFunc(group)
                }
            },
            moveUpItem:function (groupIndex) {
                this.visible = false;
                if (groupIndex > 0) {
                    if(this.groupOrderExchange){
                        if (!this.groupOrderExchange(groupIndex - 1, groupIndex)) {
                            this.visible = true;
                        }
                    }
                }

            },
            moveDownItem:function (groupIndex) {
                this.visible = false;
                if (groupIndex < this.groups.length - 1) {
                    if(this.groupOrderExchange){
                        if (!this.groupOrderExchange(groupIndex, groupIndex + 1)) {
                            this.visible = true;
                        }
                    }
                }

            }
        }
    }
</script>

<style scoped>
.menuItem{
    padding:0 5px 0 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.menuItem:hover{
    background-color: #f5f7fa;
}
</style>
<style scoped>
    .selectorPopover{
        padding-left:0!important;
        padding-right:0!important;
    }
    .selectorPopover .el-textarea__inner, .selectorPopover .el-input__inner {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #DCDFE6;
        padding:0 10px;
    }
</style>

<template>
    <el-card class="box-card questionBox" :class="{twinkleBox:element.extraUIInfo.twinkle, 'clear-shadow': st === 'dialog','activeQuestion':element.id===activeId}"
             :id="getDomId(element.id)" :data-question-id="element.id" @mousedown="$emit('mousedown')" @drag="onDrag">
        <el-form>
            <!-- style="justify-content: space-between;" -->
            <div class="flexRow" style="justify-content: space-between;">
            <div class="flexCol" style="width: calc(100% - 18px);">
                <el-tabs tab-position="left" v-model="element.extraUIInfo.activeTab">
                    <el-tab-pane :name="EDIT_MODEL.BASIC">
                        <template #label>
                            <div @pointerdown.stop>
                                <el-tooltip class="item" effect="dark" content="对变量及其问题进行设置" placement="top-start">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                            </div>
                        </template>
<!--                        <div class="tab-sub-title">对变量及其问题进行设置</div>-->
                        <div class="flexRow">
                            <el-input class="answerNameInput" type="text" size="mini" placeholder="请输入变量名" v-model="element.answerName"
                                @pointerdown.stop
                                @blur="answerNameBlur"
                                @focus="answerNameFocus"
                                @input="answerNameInput" name="answerName">
                            </el-input>

<!--                            <el-select size="mini" placeholder="变量类型" v-model="element.answerType"-->
<!--                                       @change="typeChanged()"-->
<!--                                       @visible-change="typeSelectVisibleChanged">-->
<!--                                <el-option-->
<!--                                        v-for="option in variableTypes"-->
<!--                                        :key="option.value"-->
<!--                                        :label="option.name"-->
<!--                                        :disabled="(st === 'dialog' && rt === 'text' && option.value === VARIABLE_TYPE_OPTIONS.BOOL.value) || element.extraUIInfo.typeSelectDisable"-->
<!--                                        :value="option.value">-->
<!--                                    <div><i class="icon iconfont" :class="option.icon"></i>{{option.name}}</div>-->
<!--                                </el-option>-->
<!--                            </el-select>-->

                            <answer-type-selector v-model="element.answerType"
                                                  :disable-options="getDisableOptions(element)"
                                                  @change="typeChanged" :before-change="beforeTypeChange"
                                                  @visible-change="typeSelectVisibleChanged"
                            ></answer-type-selector>
                        </div>
                        <el-form-item >
                            <el-input autocomplete="off" type="textarea" size="mini" autosize placeholder="请输入您的问题"
                                @pointerdown.stop
                                v-model="element.title"
                                @change="titleChange()"
                                      name="title"
                                :disabled="element.answerName.length === 0">
                            </el-input>
                        </el-form-item>
                        <el-form-item v-if="singleOrMultiple(element)" class="addSelectBox display__block">
<!--                            <el-select-->
<!--                                    v-model="element.options"-->
<!--                                    multiple-->
<!--                                    filterable-->
<!--                                    allow-create-->
<!--                                    default-first-option-->
<!--                                    popper-class="test"-->
<!--                                    :popper-append-to-body="false"-->
<!--                                    placeholder="可选值（输入后回车保存）">-->
<!--                            </el-select>-->
                            <options-generator :items="element.options" size="mini" @option-name-updated="optionNameUpdated"></options-generator>
                        </el-form-item>


                        <el-collapse class="defaultAnswerBox" style="margin-top: -17px;" v-model="element.extraUIInfo.highSettingExpand" @change="highSettingExpandChanged" @pointerdown.stop>
                            <el-collapse-item title="高级设置" name="1" style="text-align: left;">
                                <template #title>
                                    <div @pointerdown.stop>
                                        高级设置
                                    </div>
                                </template>
                                <!--                                <el-form-item label="问题描述" style="margin-bottom: 5px;">-->
                                <el-input type="textarea" v-model="element.description" placeholder="问题说明" @pointerdown.stop></el-input>
                                <!--                                </el-form-item>-->

                                <div>问题分组：</div>
                                <el-form-item style="margin-bottom: 5px;">
                                    <template-question-group-selector title="问题分组"  size="mini" :groups="groups" v-model:group-id="element.groupId"
                                                                      :saveItemFunc="saveGroupFunc" :deleteItemFunc="deleteGroupFunc"
                                                                      :checkSelectFunc="checkSelectGroupFunc" :groupOrderExchange="groupOrderExchange">
                                    </template-question-group-selector>
                                </el-form-item>

                                <div>默认答案设置：<span style="color: #409EFF;cursor: pointer;" @click="clearDefaultAnswer(element)" v-show="showClearDefaultAnswerButton(element)">清空</span></div>
                                <doc-question-answer-body @pointerdown.stop placeholder="默认答案" ref="answerBody" :question="element" :answer-content="element.defaultAnswer" :update-answer-func="defaultAnswerChanged"></doc-question-answer-body>
                            </el-collapse-item>
                        </el-collapse>


<!--                        <el-form-item>-->
<!--                            <template-question-group-selector size="mini" :groups="groups" v-model:group-id="element.groupId"-->
<!--                                                              :saveItemFunc="saveGroupFunc" :deleteItemFunc="deleteGroupFunc" :checkSelectFunc="checkSelectGroupFunc">-->
<!--                            </template-question-group-selector>-->
<!--                        </el-form-item>-->

<!--                        <el-collapse class="defaultAnswerBox" style="margin-top: -17px;margin-bottom: 4px;">-->
<!--                            <el-collapse-item title="默认答案" name="1">-->
<!--                                <doc-question-answer-body ref="answerBody" :question="element" :answer-content="element.defaultAnswer" :update-answer-func="defaultAnswerChanged"></doc-question-answer-body>-->
<!--                            </el-collapse-item>-->
<!--                        </el-collapse>-->

                    </el-tab-pane>

                    <el-tab-pane :name="EDIT_MODEL.LOGIC" :disabled="logicDisabled">
                        <template #label>
                            <div @pointerdown.stop>
                                <el-tooltip class="item" effect="dark" content="变量的问题的显隐控制" placement="top-start">
                                    <i class="el-icon-connection"></i>
                                </el-tooltip>
                            </div>
                        </template>
<!--                        <div class="tab-sub-title">变量的问题的显隐控制</div>-->
                        <div class="flexRow"
                             style="font-size: 12px;margin-bottom:22px;align-items: center">
                            当
                            <el-select size="mini" placeholder="控制属性"  @pointerdown.stop
                                       v-model="element.showControlAnsId" style="width: 100%;"
                                       @change="controlQuestionChanged(element)">
                                <el-option label="未指定" :value="''"></el-option>
                                <el-option
                                        v-for="option in controlItems"
                                        :key="option.id"
                                        :label="option.answerName"
                                        :value="option.id">
                                </el-option>
                            </el-select>
                        </div>

                        <div class="flexRow" style="font-size: 12px;margin-bottom:22px;align-items: center">
<!--                            为-->
                            <el-select v-model="element.showControlRelationshipType" size="mini"
                                       @pointerdown.stop
                                       style="width: 100%" value-key="value" popper-class="my-multiple-select">
                                <el-option
                                        v-for="option in getShowControlRelationshipOptions()"
                                        :key="option.value"
                                        :label="option.name"

                                        :value="option.value"
                                >
                                    <div  class="text">{{option.name}}</div>
                                </el-option>
                            </el-select>

                            <template v-if="relationshipIsValueEq(element)&&!element.extraUIInfo.refreshShowControlAnsVal">
                                <!--这里将多选单独独立出来（之前是和单选、布尔在一起的），是因为和单选布尔值融在一起，从单选或者布尔值切换到多选时会报错-->
                                <el-select
                                        @pointerdown.stop
                                        v-if="multiple(element.extraUIInfo.showControlAns)"
                                        size="mini" placeholder="值" v-model="element.showControlAnsVal"
                                        multiple
                                        value-key="value"
                                        popper-class="my-multiple-select"
                                        style="width: 100%"
                                >
                                    <el-option
                                            v-for="option in getItemSelectInfo(element.extraUIInfo.showControlAns)"
                                            :key="option.value"
                                            :label="option.name"
                                            :value="option.value"
                                    >
                                        <div  class="text">{{option.name}}</div>
                                    </el-option>
                                </el-select>

                                <el-select
                                        @pointerdown.stop
                                        v-else-if="singleOrBool(element.extraUIInfo.showControlAns)"
                                        size="mini" placeholder="值" v-model="element.showControlAnsVal"
                                        value-key="value"
                                        style="width: 100%"
                                >
                                    <el-option
                                            v-for="option in getItemSelectInfo(element.extraUIInfo.showControlAns)"
                                            :key="option.value"
                                            :label="option.name"
                                            :value="option.value"
                                    >
                                    </el-option>
                                </el-select>

                                <el-input v-else type="text" size="mini" placeholder="值" @pointerdown.stop
                                          v-model="element.showControlAnsVal" ></el-input>
                            </template>

                        </div>


                        <div class="el-form-item display__block">
                            <el-select size="mini" placeholder="显示/隐藏该问题"
                                       v-model="element.showControlAction"  @pointerdown.stop>
                                <el-option v-for="option in showControlActionValues" :key="option.id" :label="option.name" :value="option.value"></el-option>
                            </el-select>
                        </div>


                    </el-tab-pane>
                </el-tabs>
            </div>
            <div v-if="st === 'questionList'" class="flexCol" style="justify-content: start;line-height: 24px;padding: 0 2px;">
<!--                <el-link type="success" @click="saveQuestionFunc(element)" href="javascript:void(0)"><i-->
<!--                        class="el-icon-check"></i></el-link>-->
                <span class="el-link el-link--primary  is-underline" style="padding:5px 0;" href="javascript:void(0)" @click="highlightPreviousNext(element)"  @pointerdown.stop>
                    <i class="el-icon-arrow-up" @pointerdown.stop></i>
                </span>
                <span class="el-link el-link--primary  is-underline" style="padding:5px 0;" href="javascript:void(0)" @click="highlightNextRect(element)"  @pointerdown.stop>
                    <i class="el-icon-arrow-down" @pointerdown.stop></i>
                </span>
                <span class="el-link el-link--danger  is-underline" style="padding:5px 0;" href="javascript:void(0)" @click="deleteQuestionFunc(element)"  @pointerdown.stop>
                    <i class="el-icon-delete" @pointerdown.stop></i>
                </span>
            </div>
        </div>
        </el-form>
    </el-card>
</template>

<script>
    import {
        VARIABLE_TYPE_OPTIONS,
        BOOL_OPTIONS,
        EDIT_MODEL,
        SHOW_CONTROL_OPTIONS,
        noOptions,
        getQuestionDefaultAnswer,
        getItemDefaultValue,
        getShowControlRelationShipSelectInfo, SHOW_CONTROL_RELATIONSHIP_OPTIONS, convertContentToStringIFNotNull
    } from "../constant/question"
    import {
        bool,
        getItemSelectInfo,
        isTextType,
        multiple,
        single,
        singleOrBool,
        singleOrMultiple,
        isOrganization
    } from "./common/question";
    import DocQuestionAnswerBody from "./DocQuestionAnswerBody";
    import TemplateQuestionGroupSelector from "./TemplateQuestionGroupSelector";
    import {
        deleteTemplateQuestionGroup,
        downloadSystemTemplate,
        getTemplateQuestionInfoByFileId,
        saveTemplateQuestionGroup,
        getSubQuestions
    } from "../api/api";
    import {MetaMessage} from "../util";
    import OptionsGenerator from "./OptionsGenerator";
    import AnswerTypeSelector from "./AnswerTypeSelector";

    const metaMessage = new MetaMessage();

    export default {
        name: "DocQuestionEditMode",
        components: {AnswerTypeSelector, OptionsGenerator, TemplateQuestionGroupSelector, DocQuestionAnswerBody},
        props:[
            "logicDisabled",
            "element",
            "controlItems",
            "groups",
            "deleteQuestionFunc",
            "saveQuestionFunc",
            "answerNameExistFunc",
            "checkQuestionGroupChangeFunc",
            "groupOrderExchange",
            // 'checkIdDependencyFunc',
            'checkQuestionTypeChangeRelatedFunc',
            'st',
            'rt',
            "highlightNextRect",
            "highlightPreviousNext",
            "activeId",
            "pushQuestion",
            "deleteSubQuestions",
            "produceQuestionExtraUIInfo"
            ],
        data: function () {
            return {
                getItemSelectInfo:getItemSelectInfo,
                getShowControlRelationshipOptions:getShowControlRelationShipSelectInfo,
                EDIT_MODEL: EDIT_MODEL,
                VARIABLE_TYPE_OPTIONS: VARIABLE_TYPE_OPTIONS,
                variableTypes: this.getObjectValues(VARIABLE_TYPE_OPTIONS),
                boolSelectValues: this.getObjectValues(BOOL_OPTIONS),
                showControlActionValues:this.getObjectValues(SHOW_CONTROL_OPTIONS),
                bool: bool,
                multiple:multiple,
                single: single,
                isTextType:isTextType,
                singleOrBool: singleOrBool,
                singleOrMultiple: singleOrMultiple,
                saveTimeout:null,
                preAnswerName: this.element.answerName,
                noOptions: noOptions,
                changeAnswerType: false
            };
        },
        watch:{
            element:{
                handler: function(newVal,oldVal){
                    if (this.changeAnswerType === false && this.st === 'questionList') {
                        this.saveDelay(newVal)
                    }
                },
                deep: true
            },
            'element.answerName':function (newVal,oldVal) {
                if (this.element.answerName.length > 0) {
                    this.questionAnswerInfoChanged(true, oldVal)
                }
            },
            'element.answerType':function (newVal,oldVal) {
                //回答类型改变了，那么应该清空默认答案。
                this.element.defaultAnswer = getQuestionDefaultAnswer(this.element, true);
                if (this.$refs.answerBody.reInitAnswer) {
                    this.$refs.answerBody.reInitAnswer(this.element,this.element.defaultAnswer)
                }
                this.questionAnswerInfoChanged()
                if (this.st === 'questionList' && this.element.id > 0) {
                  this.changeAnswerType = true;
                  this.saveDelay(this.element, newVal, oldVal);
                }
            },
            'element.options': {
                handler: function (newVal,oldVal) {
                    this.questionAnswerInfoChanged()
                },
                deep:true
            }
        },
        methods: {
            relationshipIsValueEq: function (element) {
                return element.showControlRelationshipType === SHOW_CONTROL_RELATIONSHIP_OPTIONS.VALUE_EQ.value;
            },
            onDrag:function(e){
                this.$emit('drag', e);
            },
            highSettingExpandChanged:function(val){
                this.$emit('high-setting-expand-changed',{id:this.element.id, highSettingExpand: val})
            },
            optionNameUpdated: function ({oldName,newName}) {
                this.$emit('question-option-name-updated', {questionId: this.element.id,oldName,newName});
            },
            getDisableOptions: function (element) {
                const disabledOption = []
                if (this.st === 'dialog' && this.rt === 'text') {
                    disabledOption.push(VARIABLE_TYPE_OPTIONS.BOOL.value);
                }
                return disabledOption;
            },
            saveGroupFunc:function({id,name}){
                if (name === '') {
                    this.$message.error('请输入组名后再添加')
                    return ;
                }
                let param = null, updateGroup = null;
                if (id) {
                    updateGroup = this.findGroupById(id);
                    if (updateGroup) {
                        param = Object.assign({}, updateGroup);
                        param.name = name;
                    }
                }
                if (!param) {
                    param = {fileId: this.element.fileId, name: name};
                }

                return saveTemplateQuestionGroup(param).then(res=>{
                    if(updateGroup){
                        updateGroup.name = name;
                    }else{
                        this.groups.push(res.data.data);
                    }
                })
            },
            deleteGroupFunc: function (group) {
                deleteTemplateQuestionGroup(group.fileId,group.id).then(res => {
                    if (res.data.code === 0) {
                        let i = this.groups.indexOf(group);
                        this.groups.splice(i, 1);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                }).catch(err => {
                    this.$message.error('删除组失败')
                });
            },
            clearDefaultAnswer:function(element){
                if (multiple(element)) {
                    element.defaultAnswer = [];
                }else{
                    element.defaultAnswer = null;
                }
                if (this.$refs.answerBody.reInitAnswer) {
                    this.$refs.answerBody.reInitAnswer(this.element,element.defaultAnswer)
                }
            },
            showClearDefaultAnswerButton:function(element){
                return element.defaultAnswer && element.defaultAnswer.length > 0;
            },
            checkSelectGroupFunc: function (newGroupId) {
                console.log('checkSelectGroupFunc')
                if(!this.checkQuestionGroupChangeFunc){
                    // 如果没有传入这个方法
                    return  true;
                }
                return this.checkQuestionGroupChangeFunc(this.element, newGroupId);
            },
            findGroupById:function(id){
                return this.groups.find(g => g.id === id);
            },
            defaultAnswerChanged: function (answerContent) {
                this.element.defaultAnswer = answerContent;
            },
            answerNameFocus:function(){
                this.preAnswerName = this.element.answerName;
            },
            answerNameBlur:function(){
                if (this.answerNameExistFunc && this.answerNameExistFunc(this.element.id,this.element.answerName)) {
                    //如果该变量名已经存在了，则提示用户
                    metaMessage['error']('变量名已存在');
                    if (this.preAnswerName) {
                        this.element.answerName = this.preAnswerName;
                    }
                }
            },
            answerNameInput: function () {
                this.autoSetTitle();
            },
            getDomId: function (itemId) {
                return 'question-edit-box-' + itemId;
            },
            saveDelay:function(element, newType, oldType){
                if (element.answerName.length === 0) {
                    this.$message.error('变量名不能为空');
                    return;
                }
                if (element.extraUIInfo.deleted) {
                    //如果该元素是已经删除的，则不再保存该元素
                    clearTimeout(this.saveTimeout);
                    return;
                }
                if (this.saveTimeout) {
                    clearTimeout(this.saveTimeout);
                }
                this.saveTimeout=setTimeout(()=>{
                  this.saveQuestionFunc(element).then(res => {
                    this.changeAnswerType = false;
                      if (newType === VARIABLE_TYPE_OPTIONS.ORGANIZATION.value) {
                        // 从其他类型到组织结构类型，需要添加子问题
                       getSubQuestions(element.id).then(questionRes => {
                          if (questionRes.data.code === 0) {
                            for (const subQuestion of questionRes.data.data) {
                                subQuestion.extraUIInfo = this.produceQuestionExtraUIInfo();
                                this.pushQuestion(subQuestion);
                            }
                          }
                       });
                      }
                      if (oldType === VARIABLE_TYPE_OPTIONS.ORGANIZATION.value) {
                        // 从组织结构类型到其他类型，需要删除子问题
                        this.deleteSubQuestions(element);
                      }
                    })
                },700)
            },
            getObjectValues: function (obj) {
                let values = [];
                for (let key in obj) {
                    values.push(obj[key]);
                }
                return values;
            },
            controlAnsChanged: function (item) {
                // console.log('controlAnsChanged****************s')
                // console.log(item);
                item.showControlAnsVal = null;
                if (!item.showControlAnsId) {
                    item.extraUIInfo.showControlAns = this.getDefaultControlAns();
                    item.showControlAnsVal = "";
                    return
                }
                let question = this.findItemById(item.showControlAnsId);
                // console.log('question')
                // console.log(question)
                if (question) {
                    // console.log('controlAnsChanged****************m')
                    item.extraUIInfo.showControlAns = question;
                    item.showControlAnsVal = getItemDefaultValue(question);
                }
                // console.log('controlAnsChanged****************e')
            },
            titleChange: function () {
                this.element.extraUIInfo.titleChange = true;
            },
            // typeSelectVisibleChanged:function(show){
                // console.log('visible changed*******',show)
                // let self = this;
                // if (show) {
                //     //如果有其它问题依赖于该问题，则变量类型不允许改变
                //     // let checkResult = this.checkIdDependencyFunc(this.element, this.st !== 'dialog', '文档中已有内容与此问题关联(显隐控制)，因此无法修改问题类型。');
                //     let checkResult = this.checkQuestionTypeChangeRelatedFunc(this.element);
                //     if (checkResult.hasDependency){
                //         self.element.extraUIInfo.typeSelectDisable = true;
                //         let onlyVisibleControlArea = this.element.answerType !== VARIABLE_TYPE_OPTIONS.ORGANIZATION.value;
                //
                //         // let tip='该变量被用于了显隐控制，如果改变其类型，将自动删除绑定的显隐控制区域'
                //         const tip = this.getDependencyTip(checkResult,onlyVisibleControlArea);
                //
                //         this.$confirm(tip, '提示', {
                //             confirmButtonText: '继续',
                //             cancelButtonText: '取消',
                //             type: 'warning'
                //         }).then(() => {
                //
                //             self.$emit('delete-question-dependency',
                //                 {
                //                     question:self.element,
                //                     onlyVisibleControlArea:onlyVisibleControlArea,
                //                     callback: () => self.element.extraUIInfo.typeSelectDisable = false
                //                 }
                //             );
                //         }).catch(() => {
                //         });
                //     }else {
                //         self.element.extraUIInfo.typeSelectDisable = false;
                //     }
                // }
            // },
            beforeTypeChange:function(newVal,oldVal){
                let _this = this;
                return new Promise((resolve, reject) => {
                    if (newVal === oldVal) {
                        resolve();
                        return;
                    }
                    // console.log(`beforeType change,newVal:${newVal},oldVal:${oldVal}`);
                    let checkResult = _this.checkQuestionTypeChangeRelatedFunc(_this.element);
                    if (!checkResult.hasDependency) {
                        resolve();
                        return;
                    }
                    let onlyVisibleControlArea = _this.element.answerType !== VARIABLE_TYPE_OPTIONS.ORGANIZATION.value;
                    const tip = _this.getDependencyTip(checkResult,onlyVisibleControlArea);

                    this.$confirm(tip, '提示', {
                        confirmButtonText: '继续',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        _this.$emit('delete-question-dependency',
                            {
                                question:_this.element,
                                onlyVisibleControlArea:onlyVisibleControlArea,
                                callback: () => resolve()
                            }
                        );
                    }).catch(() => {
                        reject()
                    });
                });
            },
            typeChanged: function (newVal,oldVal) {
                console.log(`typeChanged,newVal:${newVal},oldVal:${oldVal}`)
                console.log('question type changed**********')
                console.log('this.element === ', this.element)
                this.$emit('question-changed', this.element)
                this.autoSetTitle();
                return false
            },
            autoSetTitle: function () {
                console.log(`autoSetTitle  titleChange:${this.element.extraUIInfo.titleChange}  answerName:${this.element.answerName}`)
                if (!this.element.extraUIInfo.titleChange && this.element.answerName.length > 0) {
                    this.element.title = this.titleAdapter(this.element);
                }

                return true;
            },
            titleAdapter:function(question){
                if (isTextType(question)) {
                    return `请问${this.element.answerName}是什么？`;
                } else if (bool(question)) {
                    return `请问是否${question.answerName}？`;
                } else if (singleOrMultiple(question)) {
                    return `请问${question.answerName}为？`;
                } else if (isOrganization(question)) {
                    return `请问${question.answerName}的组织名称是什么？`;
                } else {
                    return `请问${this.element.answerName}是什么？`;
                }
            },
            getDependencyTip:function(checkResult,onlyVisibleControlArea){
                let useStr = [],actionStr = [];
                if (checkResult.questionTextDependency) {
                    useStr.push(onlyVisibleControlArea?'绑定的显隐控制区域':'绑定区域');
                    actionStr.push('区域绑定关系')
                }
                if (checkResult.questionDependency) {
                    useStr.push('依存的问题')
                    actionStr.push("问题的依存关系")
                }
                return `该问题已存在${useStr.join("与")}，如果改变其类型，将自动删除${actionStr.join("及")}`;
            },
            /**
             * 决定当前问题显隐的问题已更换
             * @param item 当前问题
             */
            controlQuestionChanged: function (item) {
                this.$emit('control-question-changed',item)
            },
            /**
             * 问题的答案储存变量名或变量类型发生了改变
             */
            questionAnswerInfoChanged:function (changeAnswerName, oldAnswerName) {
                if (this.element.id > 0 && this.st !== 'dialog') {
                    this.$emit('question-answer-info-changed', this.element, changeAnswerName, oldAnswerName)
                }
            }
        }

    }
</script>

<style lang="scss">
    .my-multiple-select{
        max-width: 50vw;
        .el-select-dropdown__item{
            position: relative;
            width: 100%;
            .text{
                padding-right: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .el-select-dropdown__item.selected::after{
            top:0;
        }
    }
    .defaultAnswerBox{
        border-top: none;
        border-bottom: none;
        .el-collapse-item__wrap,.el-collapse-item__header{
            border-bottom: none;
        }
    }
    .flexRow {
        display: flex;
        flex-direction: row;
    }

    .flexCol {
        display: flex;
        flex-direction: column;
    }

    .leftMenu::-webkit-scrollbar {
        display: none;
    }

    .questionBox {
        position: relative;
        margin: 10px 0;
        cursor: pointer;
        .el-collapse{
            .el-collapse-item__header{
                font-weight: normal;
                background-color: transparent;
            }
        }

        .display__block {
            display: block;

            .el-select {
                display: block;
            }
        }

        .el-card__body {
            padding: 5px;
        }

        .el-tabs__item {
            padding: 0 5px 0 0;
        }

        //重写element plus 输入框样式。
        .el-textarea__inner, .el-input__inner {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid #DCDFE6;
        }

        .el-tabs__item:last-child {
            padding-right: 5px !important;
        }

        //隐藏掉element plus 原来的弹出可选则的内容框。
        .addSelectBox {
            margin-bottom: 9px;
            margin-top: -18px;
            .el-input__suffix-inner {
                display: none;
            }

            .el-select__popper {
                display: none;
            }
        }
    }
    .twinkleBox:after{
        content: "";
        position: absolute;
        background-color:rgba(94,201,219,0.3);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        animation: twinkle 1s  ease-in-out  1 forwards !important;
    }


    @keyframes  twinkle{
        0% {
            opacity: 0;
        }
        15% {
            opacity: 1;
        }
        30% {
            opacity: 0;
        }
        45% {
            opacity: 1;
        }
        60% {
            opacity: 0;
        }
        75% {
            opacity: 1;
        }
        80% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }

    .clear-shadow .el-card {
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    .tab-sub-title {
        text-align: center;
        font-size: 12px;
        margin-bottom: 10px;
    }

</style>
